import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

import './style.css'


const AvisoPrivacidad = ({ }) => {


    return (
        <Box>
            <Typography className='textotitulo'>
                AVISO DE PRIVACIDAD DE PÁGINA WEB
            </Typography>




            <Typography className='textonegritas'>1. - INFORMACIÓN GENERAL. </Typography>
            <Typography className='textobase'>
                Columba Funerales, S.A. de C.V. (también conocido por su nombre comercial como “<Typography className='textonegritas inline'>COLUMBA”</Typography>), con domicilio en Av. Alfredo del Mazo 2601, Interior 2, Colonia La Magdalena,
                en la ciudad de Toluca, Estado de México, México, con Código Postal 50010, es responsable de recabar sus datos personales, del uso que se le dé a los mismos y de su
                protección. Es por ello por lo que, a continuación, con fundamento en La Ley Federal de Protección de Datos Personales en Posesión de los Particulares
                (En lo sucesivo “La Ley”) y su Reglamento y con respeto al derecho de toda persona a la privacidad, y a la autodeterminación informativa, los invitamos a conocer y, en su
                caso, aceptar nuestro aviso de privacidad, cuya finalidad es explicar para qué requerimos sus datos, así como el tipo de datos que se recabarán y el tratamiento que se les
                dará. Asimismo, se explican los procedimientos que puede utilizar para ejercer sus derechos de acceso, rectificación, cancelación y oposición,
                mejor conocidos como “Derechos <Typography className='textonegritas inline'>ARCO</Typography>”.
            </Typography>

            <Typography className='textobase'>
                Para cualquier duda o trámite relacionado, se podrá poner en contacto con nuestro departamento de protección de datos personales en los siguientes medios de contacto:
            </Typography>


            <ul className='listaavisos listsizeupp'>
                <li>
                    <Typography className='textonegritas inline'>Domicilio:</Typography> Av. Alfredo del Mazo 2601, Interior 2, Colonia La Magdalena, en la ciudad de Toluca, Estado de México, México, con Código Postal 50010.
                </li>
                <li>
                    <Typography className='textonegritas inline'>Teléfono:</Typography> +52 722 830 6071
                </li>
                <li>
                    <Typography className='textonegritas inline'>Horario de atención:</Typography> El horario de atención es de 09:00 a 16:00 horas
                </li>
                <li>
                    <Typography className='textonegritas inline'>Correo electrónico: </Typography> <Link className='subrayado'>m.de.gfan@gmail.com</Link> 
                </li>
                <li>
                    <Typography className='textonegritas inline'>Nuestra página web: </Typography> <Link className='subrayado'>http://www.columbafunerales.com/</Link> 
                </li>
            </ul>


            <Typography className='textonegritas'>2. - OBJETO.</Typography>
            <Typography className='textobase'>
                Como parte de nuestro objeto social, nos dedicamos, entre otros servicios, a la oferta de servicios funerarios y servicios forenses de manera integral, servicios de entrega
                de mensajería y paquetería a instituciones, residencias, hospitales, funerarios, hoteles, restaurantes y al público en general; a comprar, vender, arrendar, administrar o
                usar los bienes inmuebles necesarios para el desarrollo de dichas actividades, así como a la prestación o contratación, por cuenta propia o de terceros, de ellos servicios
                de administración, mantenimiento, consultivos y de asesoría relacionados con el objeto social de <Typography className='textonegritas inline'>COLUMBA</Typography>, por lo que llevamos a cabo todas aquellas actividades
                y acciones relacionadas, y que se requieran para tales fines, por lo que, en el presente Aviso de privacidad, explicaremos cómo en <Typography className='textonegritas inline'>COLUMBA</Typography> recabamos, guardamos,
                utilizamos, transferimos y compartimos los datos personales, en relación con la página web, redes sociales y/o cualquier otro medio de contacto con nosotros, ya sea físico o digital .
            </Typography>



            <Typography className='textonegritas'>3. - OBTENCIÓN DE DATOS. </Typography>
            <Typography className='textobase'>
                Estos son recabados al momento en que visita nuestras instalaciones, se pone en contacto con nosotros por cualquier medio, ya sea físico o digital, nos llama por teléfono,
                escribe un e-mail, nos visita o utiliza nuestra página web, aplicaciones móviles y/o redes sociales; nos solicita información, adquiere nuestros productos y/o contrata
                nuestros servicios. Sus datos podrán ser recabados directamente por nosotros o a través de otras fuentes, y/o por terceros a nuestro nombre.
            </Typography >
            <Typography className='textobase'>También, obtendremos sus datos personales de las siguientes fuentes:</Typography>
            <ol className='listaavisos'>
                <li>
                    Directamente de usted, de nuestros clientes o sus representantes. Por ejemplo, de los documentos que nuestros clientes nos suministran en relación con los servicios para los cuales nos contratan o adquieren nuestros productos;
                </li>
                <li>
                    Indirectamente de nuestros clientes o sus representantes. Por ejemplo, mediante la información que recopilamos de nuestros clientes en el curso de la prestación de nuestros servicios;
                </li>
                <li>
                    Directa e indirectamente de la actividad registrada en nuestro sitio web, redes sociales, teléfono y otros medios de contacto, por ejemplo, de los mensajes enviados mediante estos medios;
                </li>
                <li>
                    De terceros que interactúan con nosotros en relación con los servicios que prestamos.
                </li>
            </ol>


            <Typography className='textonegritas'>4. - DATOS PERSONALES.</Typography>
            <Typography className='textobase'>
                El tratamiento de los datos personales de las personas que nos contactan y nos visitan por cualquier medio, se lleva a cabo para el cumplimiento de los fines comerciales que nos unen,
                por lo que nos constituimos como responsables del uso que le demos a sus datos y a la protección de estos. Para ello, contamos con medios tecnológicos, físicos y administrativos
                para cuidar, limitar el uso y divulgación de sus datos.
            </Typography>
            <Typography className='textobase'>
                Los datos personales y/o datos personales sensibles en caso de ser proporcionados podrán ser tratados y utilizados, en los términos a que se refiere el art. 3 fracción XVIII de la Ley,
                por COLUMBA y/o todas o alguna de sus subsidiarias y/o todas o alguna de sus filiales como por terceros. COLUMBA, podrá realizar las transferencias de los datos para que sean tratados
                dentro y fuera del país, por personas distintas a que hace mención el art. 36 y siguientes de la Ley. La información que se transfiere se destinará a las mismas finalidades a que se
                hace mención en el punto tercero del Aviso. Así pues, en el presente acto, el titular, al no manifestar su oposición para la transferencia, acepta y está consciente que sus datos
                personales y/o datos personales sensibles serán transferidos en los términos que señala la Ley.
            </Typography>
            <Typography className='textobase'>
                COLUMBA, no podrá comercializar los datos personales con terceros a efecto de que usted en calidad de titular lleve a cabo su contratación, se podrá transferir sus datos personales
                a nuestras sociedades, afiliadas o controladoras, Los datos personales sensibles en caso de ser proporcionados podrán ser tratados y utilizados, en los términos a que se refiere
                el art. 3 fracción XVIII de la Ley, por COLUMBA y/o todas o alguna de sus subsidiarias y/o todas o alguna de sus filiales como por terceros. COLUMBA, podrá realizar las transferencias
                de los datos para que sean tratados dentro y fuera del país, por personas distintas a que hace mención el art. 36 y siguientes de la Ley. La información que se transfiere se
                destinará a las mismas finalidades a que se hace mención en la cláusula tercera del Aviso.
            </Typography>
            <Typography className='textobase'>
                Los datos que recabaremos de usted serán:
            </Typography>

            <Typography className='textobase' >Datos de identificación: </Typography>
            <Typography className='textobase PdL_3'>1. Nombre completo</Typography>
            <Typography className='textobase PdL_3'>2. País</Typography>
            <Typography className='textobase PdL_3'>3. Ciudad</Typography>
            <Typography className='textobase PdL_3' >4. Identificación oficial vigente (INE, pasaporte, licencia de conducir, cédula profesional y/o cartilla militar)</Typography>
            <Typography className='textobase PdL_3'>5. Firma autógrafa digital o física</Typography>


            <Typography className='textobase' >Datos de contacto: </Typography>
            <Typography className='textobase PdL_3'>6. Domicilio</Typography>
            <Typography className='textobase PdL_3'>7. Correo electrónico</Typography>
            <Typography className='textobase PdL_3'>8. Número telefónico fijo</Typography>
            <Typography className='textobase PdL_3'>9. Número telefónico celular</Typography>
            <Typography className='textobase PdL_3'>10. Redes sociales</Typography>
            <Typography className='textobase PdL_3' >11. WhatsApp</Typography>
            <Typography className='textobase PdL_3'>12. Asunto por el cual se contacta</Typography>


            <Typography className='textobase'>Datos laborales:</Typography>
            <Typography className='textobase PdL_3'>13.	Ocupación</Typography>
            <Typography className='textobase PdL_3'>14.	Empleo, cargo o comisión</Typography>
            <Typography className='textobase PdL_3'>15.	Empresa para la que trabaja</Typography>


            <Typography className='textobase'>Datos patrimoniales o financieros:</Typography>
            <Typography className='textobase PdL_3'>16.	Datos bancarios</Typography>


            <Typography className='textobase'>
                En caso de que alguno de los datos antes mencionados no fuera necesario, no lo requeriremos, salvo aquellos que sean explícitamente solicitados, ya que,
                en caso de no contar con esta información, no estaríamos en la posibilidad de poder cumplir de manera correcta y satisfactoria con el servicio que brindamos.
                Asimismo, es importante comentarle que, en caso de no proporcionar correctamente sus datos, no tendremos responsabilidad alguna derivada de este supuesto.
            </Typography>
            <Typography className='textobase'>
                No solicitaremos de ninguna forma y en ningún momento datos personales catalogados como sensibles, es decir, aquellos datos personales íntimos o cuya utilización
                indebida pueda dar origen a discriminación o conlleve un riesgo grave para usted, tales como origen racial o étnico, estado de salud presente y/o futuro; información
                genética, creencias religiosas, filosóficas y/o morales; afiliación sindical, opiniones políticas y/o preferencia sexual. Solicitamos que, por favor, no nos
                proporcione este tipo de datos. Sin embargo, en caso de que por algún motivo nos los llegara a proporcionar, estos no serán almacenados ni tratados por nosotros.
            </Typography>


            <Typography className='textonegritas'>5. - TRATAMIENTO DE DATOS.</Typography>

            <Typography className='textobase'>
                Le comunicamos que, los datos personales que recabamos de usted, los utilizaremos para las siguientes <Typography className='textonegritas inline'>finalidades primarias</Typography>:
            </Typography>
            <ol className='listaavisos'>
                <li>Para poderle prestar correctamente nuestros servicios;</li>
                <li>Para poderle ofrecer y vender correctamente nuestros productos y servicios;</li>
                <li>Para responder cualquier duda que pueda tener respecto a nuestros productos y servicios;</li>
                <li>Para ponernos en contacto usted, respecto de cualquier relación comercial y/o jurídica que mantengamos;</li>
                <li>Para la operación y actividades de nuestra administración interna;</li>
                <li>Ordenar, procesar, catalogar, clasificar, dividir o separar y almacenar sus datos personales dentro de nuestros sistemas y archivos;</li>
                <li>Identificarle en cualquier tipo de relación comercial y/o jurídica con nosotros;</li>
                <li>Creación y administración de su cuenta de usuario para nuestras plataformas digitales y para nuestros sistemas de atención al cliente;</li>
                <li>Cumplir con las obligaciones legales y fiscales que se deriven de la relación comercial respectiva;</li>
                <li>Para prevención de fraudes;</li>
                <li>Para garantizar la seguridad de nuestras instalaciones y de las personas que en ellas se encuentran.</li>
                <li>Para permitirle el acceso a nuestras instalaciones.</li>

            </ol>
            <Typography className='textobase'>
                De manera adicional, utilizaremos sus datos para las siguientes <Typography className='textonegritas inline'>finalidades secundarias</Typography>, las cuales no son estrictamente necesarias
                para brindar los Servicios, pero que nos permiten y facilitan brindarle una mejor atención:
            </Typography>

            <ol className='listaavisos'>
                <li>Para contactarle y ofrecerle nuestros productos y servicios, y todo tipo de servicios conexos o relacionados;</li>
                <li>Llevar un registro histórico de sus datos e información, con fines de satisfacción, para desarrollar un análisis sobre sus intereses y necesidades, para brindarle así un mejor servicio;</li>
                <li>Realizarle el envío de avisos y notificaciones con fines de mercadotecnia, información de novedades, noticias, boletines, ofertas de productos, publicidad y/o marketing, ventas a distancia. Haciendo uso de medios como: correo electrónico, notificaciones PUSH, mensajes de texto (SMS);</li>
                <li>Elaboración de estudios y encuestas para identificar sus hábitos de consumo, gustos y preferencias para ofrecerle aquellos productos que se adecuen a ellos;</li>
                <li>Enviarle promociones, publicidad, beneficios y cupones de descuentos, así como de aquellas empresas pertenecientes a nuestro mismo grupo corporativo como subsidiarias, filiales, afiliadas, controladas o controladoras e, incluso, de terceros que sean nuestros socios comerciales y proveedores;</li>
                <li>Para evaluar la calidad de los servicios y productos que le brindamos;</li>
                <li>Para llevar a cabo el análisis que permita ofrecerle y en su caso otorgarte productos y/o servicios;</li>
                <li>Invitarle a participar en nuestros eventos, concursos, sorteos, promociones y actividades en redes sociales, página web, establecimientos físicos, tienda en línea, plataformas digitales o en cualquier otro medio.</li>
            </ol>
            <Typography className='textobase'>
                En caso de que no desee que sus datos personales sean tratados para las finalidades secundarias, usted puede presentar desde este momento
                su negativa para dicho uso, esto por medio del formato correspondiente que pondremos a su disposición una vez que nos lo solicite. En caso
                contrario, entenderemos que nos ha otorgado su consentimiento para dichos usos.
            </Typography>
            <Typography className='textobase'>
                La negativa para el uso de sus datos personales para estas finalidades secundarias no será ningún motivo para que le neguemos los Servicios
                y/o productos, ni para ser objeto de cualquier tipo de discriminación.
            </Typography>



            <Typography className='textonegritas'>6. - CONSENTIMIENTO. </Typography>
            <Typography className='textobase'>
                Le informamos que, en diversas acciones, como transferencia de datos sensibles, patrimoniales, financieros, se requiere su consentimiento expreso para transferir
                la información; en el supuesto de que usted no manifieste su oposición para la transferencia, acepta y está consciente que sus datos personales y/o datos personales
                sensibles serán transferidos en los términos que señala la Ley. De conformidad con lo que establece el artículo 8 de la ley, Todo tratamiento de datos personales
                estará sujeto al consentimiento de su titular, salvo las excepciones previstas por la Ley (último párrafo) el titular acepta y consiente todas y cada uno de los
                puntos del presente Aviso.
            </Typography>

            <Typography className='textonegritas'>7. - DATOS DE MENORES DE EDAD, INCAPACES Y TERCEROS.</Typography>
            <Typography className='textobase'>
                Es de nuestro especial interés cuidar los datos personales de los menores de edad e incapaces, por lo cual, por medio de nuestros canales de contacto que no sean presenciales,
                como lo son, vía telefónica, e-mail, en nuestra página web y redes sociales, entre otros, <Typography className='textonegritas'>NO</Typography> trataremos datos personales de estas personas.
            </Typography>
            <Typography className='textobase'>
                EEn el supuesto de que nos comparta datos personales de personas ajenas a usted, es decir de otros titulares de datos personales, reconoce que cuenta con el consentimiento
                y autorización de dichas personas para proporcionarnos sus datos personales y acepta la obligación de informarles que nos ha proporcionado sus datos personales, así como
                de los lugares en los que se encuentra a su disposición el presente aviso de privacidad Integral para su consulta.
            </Typography>


            <Typography className='textonegritas'>8. - TYPOGRAPHYMITACIÓN DE TRANSFERENCIA DE DATOS.</Typography>
            <Typography className='textobase'>
                Le informamos que no transferiremos sus datos personales a favor de terceros sin su consentimiento previo, a menos que se exprese lo contrario de forma expresa en este Aviso
                de Privacidad. Los terceros a quienes se les transfieran sus datos personales sólo recibirán aquellos indispensables para realizar sus actividades. Para ello, contamos con
                el apoyo de distintos proveedores que actúan bajo nuestras instrucciones en calidad de “Encargados” del tratamiento de datos. Algunos de estos encargados pueden estar ubicados
                fuera de México o del territorio norteamericano (T-Mec) produciéndose en este sentido una transferencia internacional de datos. En caso de requerir más información sobre
                nuestros encargados del tratamiento y el detalle relativo a transferencias internacionales contactándonos en los términos indicados en el presente Aviso de Privacidad.
            </Typography>
            <Typography className='textobase'>
                Además de lo anterior, algunas otras de las transferencias de datos personales que podremos realizar sin necesidad de obtener su consentimiento, de acuerdo con el artículo
                37 de La Ley, son las siguientes:
            </Typography>
            <ul className='listaavisos'>
                <li>
                    Cuando la transferencia esté prevista en una Ley o Tratado en los que México sea parte;
                </li>
                <li>
                    Cuando la transferencia sea necesaria para el seguimiento personalizado al tratamiento de glaucoma;
                </li>
                <li>
                    Cuando la transferencia sea efectuada a sociedades controladoras, subsidiarias o afiliadas bajo el control común del responsable, o a una sociedad matriz o a cualquier
                    sociedad del mismo grupo del responsable que opere bajo los mismos procesos y políticas internas;
                </li>
                <li>
                    Cuando la transferencia sea necesaria o legalmente exigida para la salvaguarda de un interés público, o para la procuración o administración de justicia;
                </li>
                <li>
                    Cuando la transferencia sea precisa para el reconocimiento, ejercicio o defensa de un derecho en un proceso judicial;
                </li>
                <li>
                    Cuando la transferencia sea precisa para el mantenimiento o cumplimiento de una relación jurídica entre el responsable, y el titular.
                </li>
            </ul>
            <Typography className='textobase'>
                Si alguna de las transferencias mencionadas anteriormente no fuera necesaria, no la realizaremos, pero aquellas que sean estrictamente necesarias se deberán de realizar con su
                consentimiento previo, ya que, en caso de no poder realizarlas o de no obtener su consentimiento, no estaríamos posibilitados de poder cumplir completamente con el servicio
                que le brindamos. Si decide no autorizar ciertas transferencias de datos personales, acepta la posibilidad de no tener acceso a los Servicios que proporcionamos, también
                acepta que no podremos completar o cumplir con aquellos procesos en los cuales requerimos de realizar dichas transferencias, sin que se genere responsabilidad alguna para nosotros.
            </Typography>
            <Typography className='textobase'>
                Si no quiere que sus datos personales sean transferidos conforme a los puntos señalados anteriormente y que requieren de su consentimiento, puede presentar desde este momento su
                negativa a dichas transferencias, esto por medio del formato correspondiente que pondremos a su disposición una vez nos lo solicite, caso contrario, si no manifiesta su negativa
                para dichas transferencias, entenderemos que nos ha otorgado su consentimiento para las mismas.
            </Typography>
            <Typography className='textobase'>
                No se transferirán tus datos personales para fines distintos a los mencionados en el presente aviso de privacidad.
            </Typography>



            <Typography className='textonegritas'>9. - CONSERVACIÓN DE DATOS.</Typography>
            <Typography className='textobase'>
                Una vez que el Servicio ha sido realizado o entregado sin incidencias, en un plazo de 5 años posteriores al último Servicio brindado por nuestra parte, sus datos serán suprimidos
                conforme a lo dispuesto en la normativa aplicable, lo que implica su bloqueo, estando disponibles tan solo a solicitud de autoridades (ejemplo: tribunales, autoridades fiscales, etc.),
                durante el plazo de prescripción de las acciones que pudieran derivar para ser posteriormente eliminados. Los plazos de prescripción varían dependiendo del tipo de Servicio, a efectos
                ejemplificativos, con carácter general, el plazo de prescripción de la mayoría de las acciones es de 5 a 10 años.
            </Typography>



            <Typography className='textonegritas'>10. - EJERCICIO DE DERECHOS “ARCO”.</Typography>
            <Typography className='textobase'>
                Como Usuario, tiene derecho a conocer qué datos personales tenemos de usted, para qué los utilizamos y las condiciones del uso que les damos (Acceso). Asimismo,
                es su derecho solicitar la corrección de su información personal en caso de que esté desactualizada, sea inexacta o incompleta (Rectificación); que la eliminemos de
                nuestros registros o bases de datos cuando considere que la misma no está siendo utilizada adecuadamente (Cancelación); así como oponerse al uso de sus datos personales
                para fines específicos (Oposición). Estos derechos se conocen como derechos ARCO.
            </Typography>
            <Typography className='textobase'>
                Para conocer el procedimiento y requisitos para el ejercicio de los derechos ARCO, o si quiere ejercer cualquiera de los derechos mencionados, deberá de contactar con nuestro
                departamento de protección de datos personales por cualquiera de sus medios de contacto, y este mismo le facilitará el formato de solicitud respectivo.
            </Typography>
            <Typography className='textobase'>
                Si usted considera que su derecho a la protección de sus datos personales ha sido lesionado por alguna conducta u omisión de nuestra parte, o presume alguna violación a las
                disposiciones previstas en la Ley, su Reglamento y demás ordenamientos aplicables, podrá interponer su inconformidad o denuncia ante el Instituto Nacional de Transparencia,
                Acceso a la Información y Protección de Datos Personales (INAI). Para más información, le sugerimos visitar su página oficial de Internet 
                <Typography className='textobase inline subrayado'> www.inai.org.mx.</Typography>
            </Typography>




            <Typography className='textonegritas'>11. - REVOCACIÓN DE CONSENTIMIENTO.</Typography>
            <Typography className='textobase'>
                Puede revocar su consentimiento que, en su caso, nos haya otorgado para el tratamiento de sus datos personales. Sin embargo, es importante que tenga en cuenta que no en todos
                los casos podremos atender su solicitud o concluir el uso de forma inmediata, ya que es posible que por alguna obligación legal requiramos seguir tratando sus datos personales.
                Asimismo, deberá considerar que, para ciertos fines, la revocación de su consentimiento implicará que no le podamos seguir prestando el servicio que nos haya solicitado,
                o la conclusión de su relación con nosotros.
            </Typography>
            <Typography className='textobase'>
                Para conocer el procedimiento y requisitos para la revocación del consentimiento o para iniciar un procedimiento para revocar su consentimiento, deberá de contactar con el
                Departamento de Protección de Datos y presentar su solicitud, tomando en consideración los medios de contacto del numeral 1 del presente Aviso.
            </Typography>



            <Typography className='textonegritas'>12. - MEDIDAS DE SEGURIDAD.  </Typography>
            <Typography className='textobase'>
                Con objeto de que pueda limitar el uso y la divulgación de sus datos personales, le ofrecemos los siguientes medios:
            </Typography>

            <ol className='listaavisos'>
                <li>
                    Se podrá inscribir en el Registro Público para Evitar Publicidad, que está a cargo de la Procuraduría Federal del Consumidor (PROFECO) en www.repep.profeco.gob.mx, con la finalidad de que sus
                    datos personales no sean utilizados para recibir publicidad o promociones de empresas de bienes o servicios. Para más información sobre este registro, deberá de consultar el portal de Internet
                    de la PROFECO, o bien ponerse en contacto directamente con ésta.
                </li>
            </ol>




            <Typography className='textonegritas'>13. - CAMBIOS AL PRESENTE AVISO DE PRIVACIDAD</Typography>
            <Typography className='textobase'>
                Le garantizamos que sus datos personales serán tratados bajo estrictas medidas de seguridad, tanto administrativas, técnicas y físicas, las cuales están previstas en la normativa aplicable,
                según sean las más apropiadas de acuerdo con el tipo de datos personales en cuestión y del tratamiento al que están sujetos, garantizamos su confidencialidad en todo momento, evitando
                accesos no autorizados, daño, pérdida, alteración o destrucción de tus datos personales.
            </Typography>



            <Typography className='textonegritas'>14. - EJERCICIO DE DERECHOS “ARCO”.</Typography>
            <Typography className='textobase'>
                Nos reservamos el derecho de modificar en cualquier momento el presente aviso de privacidad a nuestra sola discreción o por causas de cambios legislativos o jurisprudenciales.
                En caso de que lo modifiquemos, pondremos nuestro nuevo aviso de privacidad a su disposición en los siguientes medios: a) en nuestra página web  
                <Typography className='textobase inline subrayado'> http://www.columbafunerales.com/ </Typography> o;
                b) se lo haremos llegar vía correo electrónico a la dirección de correo electrónico que nos haya proporcionado, para lo cual, le invitamos a estar pendiente con la finalidad de que
                permanezca informado de cualquier cambio. Si continúa haciendo uso de nuestros Servicios, una vez que le hayamos informado de los cambios en nuestro aviso de privacidad, significará
                que está de acuerdo con los nuevos términos, excepto en relación con posibles nuevas finalidades de tratamiento de datos en las que necesitemos su consentimiento.
            </Typography>



            <Typography className='textonegritas'>15. - REVOCACIÓN DE CONSENTIMIENTO.</Typography>
            <Typography className='textobase'>
                Salvo en los casos que sea necesario obtener su consentimiento de manera expresa y por escrito, de acuerdo con el Artículo 8 de La Ley, se entenderá que ha consentido el tratamiento
                de sus datos, cuando habiéndose puesto a su disposición el presente aviso de privacidad, no manifieste su oposición al mismo. Si no nos entregó sus datos de manera directa, tendrá
                5 días hábiles para manifestar su oposición a nuestro aviso de privacidad, de lo contrario, entenderemos que nos ha dado su consentimiento para poder usar su información.
            </Typography>
            <Typography className='textobase'>
                Al proporcionarnos información, ya sea de manera física, electrónica o por cualquier otro medio, declara que ha leído el presente aviso de privacidad y entiende de su alcance,
                y al no manifestar su oposición por escrito, otorga su consentimiento y expresa su entera conformidad y confirma que está de acuerdo con los términos y condiciones aquí contenidos.
                Si no estuviera de acuerdo con cualquier término del presente aviso de privacidad, por favor no proporcione ningún dato personal.
            </Typography>
            <Typography className='textobase'>
                De acuerdo con la normatividad aplicable, no será necesario obtener su consentimiento para el tratamiento de sus datos personales en los siguientes casos:
            </Typography>

            <Box className='PdL_3'>
                <Typography className='textobase'>
                1.	Esté previsto en una Ley;
                </Typography>
                <Typography className='textobase'>
                2.	Los datos figuren en fuentes de acceso público;
                </Typography>
                <Typography className='textobase'>
                3.	Los datos personales se sometan a un procedimiento previo de disociación;
                </Typography>
                <Typography className='textobase'>
                4.	Tenga el propósito de cumplir obligaciones derivadas de una relación jurídica entre el titular y el responsable;
                </Typography>
                <Typography className='textobase'>
                5.	Exista una situación de emergencia que potencialmente pueda dañar a un individuo en su persona o en sus bienes;
                </Typography>
                <Typography className='textobase'>
                6.	Sean indispensables para la atención médica, la prevención, diagnóstico, la prestación de asistencia sanitaria, tratamientos médicos 
                o la gestión de servicios sanitarios, mientras el titular no esté en condiciones de otorgar el consentimiento, en los términos que establece 
                la Ley General de Salud y demás disposiciones jurídicas aplicables, y que dicho tratamiento de datos se realice por una persona sujeta al
                 secreto profesional u obligación equivalente, o;
                </Typography>
                <Typography className='textobase'>
                7.	Se dicte resolución de autoridad competente.
                </Typography>
            </Box>
            <Typography className='textobase'>
                De conformidad con lo que establece el artículo 9 de la Ley, el titular acepta y consiente todos y cada uno de los puntos del presente Aviso, suscribiendo el presente.
            </Typography>
            <Typography className='textobase'>
                Al interactuar a través de SMS o cualquier otro mecanismo dispuesto, el usuario autoriza que se le puede contactar para brindar más información promocional o acerca de los
                productos y servicios que pueda tener o potencialmente adquirir con nosotros, adicionalmente el usuario podrá proporcionarnos su autorización mediante escritos,
                especialmente dispuestos para ello o mediante llamada de voz, a fin de que pueda ser contactado a través de cualquier otro medio que tenga disponible 
                <Typography className='textonegritas inline'> COLUMBA</Typography> o para solicitar la baja.            
            </Typography>
            <Typography className='textobase'>
                Asimismo, los Datos Personales proporcionados por el Titular, con excepción de Datos Personales sensibles podrán ser utilizados para fines comerciales y promocionales, para el
                envío de información comercial y publicitaria, incluidos los envíos por correo electrónico, telefonía celular (mensaje SMS, MMS, entre otros) o todo medio de comunicación
                electrónica similar o que pueda llegar a desarrollarse, así como para encuestas de calidad y satisfacción, análisis de uso de productos, estadísticas para el envío de
                avisos acerca de productos y servicios operados por el Responsable y cuando otras Leyes lo requieran.
            </Typography>
            <Typography className='textonegritas'>
                Este aviso de privacidad fue elaborado el día 06 de noviembre de 2024.
            </Typography>



        </Box>
    );
}

export default AvisoPrivacidad;