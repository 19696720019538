import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

import './style.css'

const AcuerdoTerminosCondiciones = ({ }) => {

    return (
        <Box width='100%'>

            <Typography className='textotitulo'>
                TÉRMINOS Y CONDICIONES DE USO DE PÁGINA WEB “Columba”
            </Typography>

            <Typography className='textonegritas'>
                Por favor lea los siguientes Términos y Condiciones antes de utilizar nuestros sistemas de información electrónicos.
            </Typography>

            <Typography className='textonegritas'>
                Actualizados al lunes 13 de noviembre del 2024
            </Typography>

            <Typography className='textobase'>
                Le informamos que el Sitio es propiedad de Columba Funerales, S.A. de C.V. (“<Typography className='textonegritas inline'>COLUMBA”</Typography>), quien es
                comercialmente conocido como Columba y contamos con Registro Federal de Contribuyentes CFU210521IZ2.
            </Typography>

            <Typography className='textobase'>
                Este contrato describe los Términos y Condiciones que establecen las disposiciones, derechos y obligaciones a las que le sujetará y obligará en su calidad de “Usuario”, al momento
                de ingresar o hacer uso del sitio web <Link className='vinculo'>www.columbafunerales.com</Link>  , así como de las plataformas digitales del Columba Funerales, S.A. de C.V (todos los anteriores serán llamados
                como el “Sitio”). Buscamos en todo momento, garantizarle, transparentarle, informarle y orientarle adecuadamente sobre la navegación a través de nuestro Sitio, comprometiéndonos
                a mantener siempre públicos estos Términos y Condiciones.
            </Typography>

            <Typography className='textobase'>
                A su vez, el usuario se sujetará a todas y cada una de las condiciones generales y particulares incluidas en los Términos y Condiciones de Uso aquí contenidos, así como de todas
                las leyes y reglamentos aplicables de conformidad a la legislación vigente y aplicable para el uso del Sitio y declara tácitamente su aceptación utilizando para tal efecto
                medios electrónicos, en términos de lo dispuesto en el artículo 1803 del Código Civil Federal de los Estados Unidos Mexicanos.
            </Typography>

            <Typography className='textobase'>
                En caso de que sean violados estos Términos y Condiciones, <Typography className='textonegritas inline'>COLUMBA</Typography> tiene la facultad de cancelar su uso
                de La Página Web, excluirlo del uso en operaciones que pretenda realizar en el Sitio en un futuro, cancelar beneficios que haya recibido a través del Sitio, y/o tomar
                la acción legal que juzgue conveniente para sus intereses.
            </Typography>

            <Typography className='textobase'>
                Todos los productos y/o servicios que son ofrecidos por La Página Web pudieran ser creados, cobrados, o presentados en otras páginas web propiedad de
                <Typography className='textonegritas inline'> COLUMBA</Typography> y en tal caso estarían sujetas a sus propios Términos y Condiciones,
                asimismo, en lo no previsto en dichas páginas Web y sus términos y condiciones se estará sujeto a lo señalado en los presentes términos y condiciones.
            </Typography>

            <Typography className='textobase'>
                Usted puede limitar el uso o divulgación de sus datos personales para dejar de recibir mensajes publicitarios o promocionales, registrando su número
                telefónico en el "Registro Público Para Evitar Publicidad" de la Procuraduría Federal del Consumidor. Para más información al respecto, visite la página
                de internet <Link className='vinculo'>www.profeco.gob.mx</Link> 
            </Typography>

            <Typography className='textobase'>
                Nuestros medios de contacto para todo lo relacionado a los presentes Términos y Condiciones son:
            </Typography>

            <Box>

            </Box>

            <ul className='listaacuerdo listsizeupp'>
                <li>
                    <Typography className='textonegritas inline'>Domicilio:</Typography> Av. Alfredo del Mazo 2601, Interior 2, Colonia La Magdalena, en la ciudad de Toluca, Estado de México, México, con Código Postal 50010.
                </li>
                <li>
                    <Typography className='textonegritas inline'>Teléfono:</Typography> +52 722 830 6071
                </li>
                <li>
                    <Typography className='textonegritas inline'>Horario de atención:</Typography> El horario de atención es de 09:00 a 16:00 horas
                </li>
                <li>
                    <Typography className='textonegritas inline'>Correo electrónico: </Typography> <Link className='vinculo'>m.de.gfan@gmail.com</Link> 
                </li>
                <li>
                    <Typography className='textonegritas inline'>Nuestra página web: </Typography> <Link className='vinculo'>http://www.columbafunerales.com/</Link> 
                </li>
            </ul>

            <Typography className='textobase'>
                El Sitio estará disponible a través de diversas plataformas electrónicas que permiten su uso gratuito, las cuales serán:
            </Typography>

            <ol className='listaacuerdo '>
                <li className='bold'> Página web:
                    <ol className='sublistaacuerdo' type='a'>
                        <li> <Link className='vinculo'>http://www.columbafunerales.com/</Link></li>
                    </ol>
                </li>
                <li className='bold'> WhatsApp:
                    <ol className='sublistaacuerdo' type='a'>
                        <li>+52 722 784 6600</li>
                    </ol>
                </li>
                <li className='bold'> Redes Sociales:
                    <ol className='sublistaacuerdo' type='a'>
                        <li>FALTA AGREGAR ENLACE DE REDES SOCIALES CUANDO ESTEN HABILITADAS</li>
                    </ol>
                </li>
            </ol>

            <Typography className='textonegritas'>
                CLÁUSULA 1. DISPONIBILIDAD, ACCESIBILIDAD O INTERRUPCIÓN DEL SITIO.
            </Typography>

            <Typography className='textobase'>
                Nos comprometemos a intentar mantener el Sitio siempre disponible. Como requisito para acceder al Sitio, deberá de contar con la mayoría de edad de conformidad con la legislación
                aplicable, absteniéndose a ingresar si tiene algún impedimento legal para llevar a cabo actos jurídicos. Nos comprometemos a no establecer preferencias injustificadas o indebidas
                o discriminación alguna respecto a los Usuarios del Sitio.
            </Typography>
            <Typography className='textobase'>
                En caso de que por alguna razón no se permita la accesibilidad o se lleve a cabo una interrupción en el Sitio, podrá reportar tal situación en los medios de contacto aquí establecidos
                , a efectos de que podamos solucionar el problema a la brevedad posible.
            </Typography>

            <Typography className='textonegritas'>
                CLÁUSULA 2. DOMICILIO Y ÁREA DE SERVICIOS.
            </Typography>

            <Typography className='textobase'>
                Al hacer uso del Sitio, reconoce que nuestro domicilio está ubicado en Av. Alfredo del Mazo 2601, Interior 2, Colonia La Magdalena, en la ciudad de Toluca, Estado de México,
                México y el alcance y área de nuestras operaciones de servicio serán solamente en nuestras instalaciones. Las ofertas, promociones, bienes, productos o servicios que publiquemos,
                salvo que se indique expresamente lo contrario, serán únicamente válidas y estarán limitadas a las áreas antes mencionadas y sujetas a disponibilidad de nuestra parte.
            </Typography>

            <Typography className='textonegritas'>
                CLÁUSULA 3. CONTACTO DEL USUARIO, REGISTRO Y CUENTAS.
            </Typography>

            <Typography className='textobase'>
                Como Usuario del Sitio, podrá conocer nuestros bienes, productos o servicios del Sitio y podrá ponerse en contacto con nosotros por medio del llenado del formulario correspondiente
                con sus datos y el mensaje que quieras enviarnos, así mismo podrá crear una cuenta, por lo que será su responsabilidad mantener la confidencialidad de la información de su cuenta
                y contraseña, así como de todas las actividades que se realicen bajo su cuenta. Si sospecha que su cuenta ha sido utilizada de manera distinta o no autorizada, deberá notificarnos
                inmediatamente.
            </Typography>
            <Typography className='textobase'>
                Respecto de la cuenta en mención podrá registrarse o iniciar sesión en La Página Web mediante redes sociales, para lo cual tendrá la responsabilidad de revisar el Aviso de Privacidad
                de estos servicios, liberando de toda responsabilidad a La Página Web por el uso que dichos servicios brinden a sus datos.
            </Typography>
            <Typography className='textobase'>
                En todo caso, los datos proporcionados por el Usuario serán utilizados y protegidos por COLUMBA de conformidad al Aviso de Privacidad que podrá visualizar en La Página Web.
            </Typography>
            <Typography className='textobase'>
                Nos comprometemos a utilizar la información que nos proporcione de forma confidencial, asegurándole que no vamos a difundirla o transmitirla a terceros, salvo autorización
                expresa de su parte o por requerimiento de alguna autoridad competente.
            </Typography>

            <Box >
                <Typography className='subclausula bold'>a. Otorgamiento de su información.</Typography>
                <Typography className='textobase'>
                    Respecto de la cuenta en mención podrá registrarse o iniciar sesión en La Página Web mediante redes sociales, para lo cual tendrá la responsabilidad de revisar el Aviso de Privacidad
                    de estos servicios, liberando de toda responsabilidad a La Página Web por el uso que dichos servicios brinden a sus datos.
                </Typography>
            </Box>

            <Box >
                <Typography className='subclausula bold'>b.	Responsabilidad respecto de la información que otorgues.</Typography>
                <Typography className='textobase'>
                    Usted es el único responsable de la veracidad, exactitud y certeza de los datos y la información que nos proporcione para contactarnos. Cualquier error o perjuicio derivado de
                    información y datos erróneos otorgados de su parte, nos liberará de toda responsabilidad que pudiera ocasionarse, siendo usted el único responsable de un hecho relacionado.
                </Typography>
            </Box>

            <Box >
                <Typography className='subclausula bold'>c.	De los beneficios y ofertas.</Typography>
                <Typography className='textobase'>
                    COLUMBA pondrá a disposición de todos los usuarios diversos descuentos y promociones exclusivos, ofrecidos por COLUMBA, por lo que el Usuario acepta y reconoce que los servicios y/o
                    productos, mismos que se concertarán de manera directa entre el Usuario y COLUMBA, por lo que, para tal efecto, el Usuario deberá presentar el código, número y/o cupón
                    correspondiente al descuento, o beneficio otorgado en La Página Web, las cuales serán temporales y no serán acumulables con otras promociones. Los Servicios ofrecidos por COLUMBA
                    se regirán por el contrato, convenio o membresía que, para tal efecto, se genere y sea firmado por COLUMBA y el Usuario.
                </Typography>
            </Box>

            <Typography className='textonegritas'>
                CLÁUSULA 4. SERVICIOS QUE SE OFRECEN Y PROCEDIMIENTO PARA ADQUIRIR BIENES, PRODUCTOS O SERVICIOS EN EL SITIO.
            </Typography>

            <Typography className='textobase'>
                Los servicios ofrecidos por COLUMBA en la página web, podrán consistir, de manera enunciativa más no limitativa, en los enlistados a continuación:
            </Typography>

            <ul className='sublista listsizeupp' >
                <li>Servicios de crematorio;</li>
                <li>Servicios fúnebres;</li>
                <li>Servicios de embalsamiento de cadáveres;</li>
                <li>Servicios de arreglos de cadáveres con fines estéticos,</li>
                <li>Servicios de cremación,</li>
                <li>Servicios de inhumación;</li>
                <li>Servicios funerarios;</li>
                <li>Servicios de planes a futuro (servicios de adquisición de servicios funerarios de manera anticipada);</li>
                <li>Servicios de representación y asesoramiento jurídico en el ámbito funerario;</li>
                <li>
                    Servicios de comercialización de ataúdes, cofres funerarios, espejos, marcos, muebles para su uso en funerarias, mobiliario, muebles, pedestales (muebles), urnas funerarias,
                    monumentos funerarios metálicos, monumentos funerarios no metálicos, lapidas sepulcrales, lapidas conmemorativas, entre otros productos relacionados al ámbito funerario;
                </li>
                <li>
                    Servicios de aplicaciones móviles de comercio electrónico que permite a los usuarios realizar transacciones comerciales electrónicas a través de una red informática en el
                    ámbito funerario;
                </li>
                <li>Servicios de un software interactivo para los negocios en el ámbito funerario; </li>
                <li>Servicios de un software para realizar transacciones en línea en el ámbito funerario; </li>
                <li>
                    Servicios de un software para supervisar, analizar, controlar y ejecutar operaciones en el mundo físico, en el ámbito funerario;
                </li>
                <li>Servicios de un software para uso comercial en el ámbito funerario; </li>
                <li>Servicios de un software para uso comercial en el ámbito funerario; </li>
                <li>Servicios de un software y aplicaciones para dispositivos móviles en el ámbito funerario;</li>
                <li>Servicios de entretenimiento en el ámbito funerario; </li>
                <li>Servicios educativos en el ámbito funerario; </li>
                <li>Servicios de formación en el ámbito funerario; </li>
                <li>Servicios de suministro en línea de contenido de audio no descargable en el ámbito funerario;</li>
                <li>Servicios de producción y grabación de contenidos audiovisuales en el ámbito funerario; </li>
                <li>
                    Servicios de publicación de contenidos de entretenimiento multimedia, de audio y de vídeo digital en el ámbito funerario;
                </li>
                <li>Servicios de actividades culturales en el ámbito funerario; </li>
                <li>Servicios de organización y planeación de eventos sociales en el ámbito funerario; </li>
                <li>Servicios de organización y planeación de recepciones en el ámbito funerario;</li>
                <li>
                    Servicios científicos y tecnológicos, así como servicios de investigación y diseño conexos relacionados a la provisión de servicios funerarios, de inhumación y de cremación;
                </li>
                <li>
                    Servicios de análisis industrial, investigación y diseño industrial relacionados a la provisión de servicios funerarios, de inhumación y de cremación;
                </li>
                <li>
                    Servicios de control de calidad relacionados a la provisión de servicios funerarios, de inhumación y de cremación;
                </li>
                <li>
                    Servicios de diseño y desarrollo de equipos de hardware y software relacionados a la provisión de servicios funerarios, de inhumación y de cremación;
                </li>
                <li>Servicios de preparación de alimentos y bebidas en eventos funerarios; </li>
                <li>Servicios de provisión de alimentos y bebidas en eventos funerarios; </li>
                <li>Servicios de hospitalidad (hospitality) en el ámbito funerario; </li>

            </ul>

            <Typography className='textobase'>
                Por medio del uso del Sitio, podrá conocer nuestros bienes, productos o servicios, pero solamente podrá adquirirlos y contratarlos con el registro de una cuenta o en nuestras
                instalaciones. No realizamos ningún tipo de cobro por medio del Sitio ni canales que sean físicamente.
            </Typography>


            <Typography className='textobase'>
                El Usuario podrá realizar los pagos por medio del procesador de pagos que utiliza Columba, en este caso 
                <Typography className='textobase inline subrayado '>las transacciones serán efectuadas mediante la pasarela de Openpay.</Typography>
            </Typography>

            <Typography className='textonegritas'>CLÁUSULA 5. SUS DERECHOS COMO CONSUMIDOR</Typography>

            <Typography className='textobase'>
                Como consumidor, siempre tendrá los siguientes derechos respecto de cualquier compra realizada con nosotros.
            </Typography>

            <ul className='listaacuerdo listsizeupp'>
                <li  >a. Devolución o Cancelación.</li>
                <li  >b. Cambios.</li>
                <li  >c. Garantías.</li>
            </ul>

            <Typography className='textobase'>
                Estos derechos serán variables dependiendo de cada bien, producto o servicio adquirido y deberá de consultar sus especificaciones en lo particular.
            </Typography>

            <Typography className='textonegritas'>
                CLÁUSULA 6. REVOCACIÓN DE SU CONSENTIMIENTO
            </Typography>

            <Typography className='textobase'>
                Podrá revocar su consentimiento a cualquier Compra realizada hasta 5 cinco días naturales posteriores a la entrega del bien o producto por nuestra parte, siempre y cuando no haya
                utilizado el bien o el servicio adquirido no haya sido brindado.
            </Typography>
            <Typography className='textobase'>
                Lo anterior procederá únicamente cuando el bien o producto de su Compra, haya sido conservado en el estado original en que le fue entregado. Nos obliga a informarle en las
                condiciones de compra de cada producto, aquellos bienes o productos que por su naturaleza no son objeto de devolución por revocación del consentimiento.
            </Typography>


            <Typography className='textonegritas'>
                CLÁUSULA 7. MEDIOS PARA COMUNICARNOS
            </Typography>

            <Typography className='textobase'>
                Acuerda y nos autoriza a asumir que toda información o comunicación de cualquier naturaleza que envíe desde cualquier dirección de correo electrónico o número telefónico registrado
                desde el Sitio, o aquellas comunicaciones realizadas a través del Sitio, serán vinculantes para usted como Usuario y se entenderán como una forma de manifestación expresa y por
                escrito de su voluntad, estas comunicaciones podrán ser conservada para los efectos legales que se puedan generar.
            </Typography>
            <Typography className='textobase'>
                También reconoce que cualquier mensaje que le enviemos por cualquier medio, lo tendrá por recibido de forma escrita. Acepta que todos los contratos, convenios, acuerdos, avisos y otras
                notificaciones o comunicaciones que realicemos por medios electrónicos o mediante el Sitio, satisfacen cualquier requisito legal de forma escrita.
            </Typography>


            <Typography className='textonegritas'>

                CLÁUSULA 8. TÉRMINOS GENERALES DE LOS “SERVICIOS” Y “PEDIDOS”.
            </Typography>

            <Typography className='textobase'>
                Todos y cada uno de los Servicios que tenemos disponibles para su conocimiento, estarán regulados según estos Términos y Condiciones, así como los Anexos a estos Términos y Condiciones,
                los cuales serán identificados como Términos Adicionales.
            </Typography>
            <Typography className='textobase'>
                El Usuario reconoce que las ventas de los servicios enlistados en el numeral Cuarto se llevarán a cabo a través de La Página Web, o en las instalaciones de Columba. Para ello, se
                consideran los siguientes términos, reglas y condiciones generales para la provisión de dichos servicios:
            </Typography>

            <ul className='listaacuerdo listsizeupp'>
                <li className='bold' >
                    Servicio:
                    <Typography className='textobase inline'>
                        Todos y cada uno de los Servicios que tenemos disponibles para su conocimiento, estarán regulados según estos Términos y Condiciones, así como los Anexos a estos Términos
                        y Condiciones, los cuales serán identificados como Términos Adicionales.
                    </Typography>
                    <ul className='listaacuerdo listsizeupp'>
                        <li className='bold' >
                            Servicio en seguimiento, “estatus”
                            <Typography className='textobase inline'>
                                Este permitirá a COLUMBA distinguir en que posición dentro del servicio se encuentra el Usuario y se encargara de rastrear que se haya cumplido la información tal y cual
                                fue solicitada y que su estatus si se encuentre actualizado. (los estatus son los mismos que el seguimiento de un servicio en La Página Web; en preservación, inicio
                                de velación, instalación, conmemoración).
                            </Typography>
                        </li>
                        <li className='bold' >
                            Servicio concluido:
                            <Typography className='textobase inline'>
                                Este servicio refiere a que el servicio solicitado por el Usuario se encuentra completado y sin ningún pendiente para su realización.
                            </Typography>
                        </li>
                        <li className='bold' >
                            Servicio cancelado:
                            <Typography className='textobase inline'>
                                Este es un servicio que fuese contratado por el Usuario, pero que fue cancelado y, en consecuencia, el Usuario asume las cláusulas de cancelación del servicio.
                            </Typography>
                        </li>
                    </ul>
                </li>

                <li className='bold' >
                    Pedido:
                    <Typography className='textobase inline'>
                        Se entenderá como tal al termino que se usará para el inicio de la compra comenzando desde la ventana en donde se recaba la información de datos de contacto.
                        De no completar ninguna información no será contemplado como pedido. Estos pedidos tendrán una subdivisión en el que serán visibles para el Usuario en el sistema
                        de escritorio con los siguientes términos:
                    </Typography>
                    <ul className='listaacuerdo listsizeupp'>
                        <li className='bold' >
                            Pedido incompleto:
                            <Typography className='textobase inline'>
                                Este pedido ha guardado al menos el número de contacto o correo electrónico del Usuario para que COLUMBA se pueda comunicar, como parte del seguimiento,
                                con el Usuario.
                            </Typography>
                        </li>
                        <li className='bold' >
                            Pedido por validar pago:
                            <Typography className='textobase inline'>
                                Este pedido refiere a que el Usuario completó los formularios correspondientes y puso su forma de pago como tienda, deposito o transferencia
                                y aun no se refleja ningún movimiento en la cuenta.
                            </Typography>
                        </li>
                        <li className='bold' >
                            Pedido pagado:
                            <Typography className='textobase inline'>
                                Es aquel pedido con toda la información y con el pago hecho mediante tarjeta de débito o crédito y su pago ha sido validado.
                            </Typography>
                        </li>
                        <li className='bold' >
                            Pedido cancelado:
                            <Typography className='textobase inline'>
                                Es el pedido que no se pudo localizar, y que el Usuario ya no desea concretar con COLUMBA.
                            </Typography>
                        </li>
                    </ul>
                </li>
                <li className='bold' >
                    Plazo de entrega del Pedido y Servicio:
                    <Typography className='textobase inline'>Se entenderá que el plazo para para la entrega del Pedido o Servicio estará sujeto a disponibilidad de estos y a verificación
                        de la información de contacto, servicio y pago realizado.
                    </Typography>
                </li>
            </ul>

            <Typography className='textobase'>
                Todos y cada uno de los servicios que se ofrecen en La Página Web estarán regulados según estos Términos y Condiciones, así como los Anexos que, en su caso, se llegaren
                a implementar, los cuales serán identificados como Términos Adicionales.
            </Typography>
            <Typography className='textobase'>
                Todas las compras y transacciones que se lleven a cabo por medio de La Página Web <Typography className='textobase inline subrayado '>serán efectuadas mediante la pasarela de <Typography className='textonegritas inline'>Openpay</Typography> </Typography>   y estarán sujetas a un proceso de
                confirmación y verificación, el cual podría incluir lo siguiente: (i) la verificación del inventario y disponibilidad de producto, o servicio, (ii) validación de la
                forma de pago, (iii) validación de la factura (en caso de existir) y (iv) el cumplimiento de las condiciones requeridas por el medio de pago seleccionado. En algunos
                casos puede que se requiera una verificación por medio de correo electrónico o número telefónico, o acreditar la personalidad.
            </Typography>

            <Typography className='textonegritas'>
                CLÁUSULA 9. SOLUCIÓN DE RECLAMACIONES O ACLARACIONES.
            </Typography>
            <Typography className='textobase'>
                Para cualquier reclamación o aclaración estaremos a su disposición en los medios de contacto que se señalan en estos Términos y Condiciones de lunes a viernes en un horario
                de 09:00 horas a las 14:00 horas. Dispondremos de un plazo no mayor a 10 diez días hábiles para poder resolver cualquier reclamación o aclaración que haya presentado y lo haremos
                al correo electrónico que nos indique para tal efecto.
            </Typography>

            <Typography className='textonegritas'>
                CLÁUSULA 10. RESPONSABILIDAD y RESTRICCIÓN DEL USUARIO.
            </Typography>
            <Typography className='textobase'>
                Al utilizar La Página Web el Usuario acepta lo siguiente:
            </Typography>

            <ul className='listaacuerdo listsizeupp'>
                <li>Compartir información veraz, exacta y certera.</li>
                <li>No infringir en derechos de terceros, incluidos los derechos de propiedad intelectual o privacidad.</li>
                <li>No utilizar La Página Web para distribuir virus u otros códigos maliciosos.</li>
                <li>Realizar acciones que puedan interrumpir o dañar el funcionamiento de La Página Web.</li>
                <li>Actuar de conformidad con estos Términos y Condiciones.</li>
                <li>El uso de Sitio está restringido para menores de edad, por lo cual, si ingresa o utiliza el Sitio, confirma que tiene 18 o más años.</li>
            </ul>


            <Typography className='textonegritas'>
                CLÁUSULA 11. MECANISMOS DE SEGURIDAD
            </Typography>
            <Typography className='textobase'>
                En este Sitio contamos con medidas de seguridad a efectos de garantizarle una experiencia de compra segura y garantizada, con medidas de seguridad
                como el certificado SSL y forzado de HTTPS.
            </Typography>


            <Typography className='textonegritas'>
                CLÁUSULA 12. CONSENTIMIENTO DEL USO DEL SITIO
            </Typography>
            <Typography className='textobase'>
                Si ingresa o utiliza de cualquier manera el Sitio, significa que acepta expresamente los presentes Términos y Condiciones. En caso de que no esté de acuerdo o no acepte
                de forma completa estos Términos y Condiciones, deberá de abstenerse de acceder, utilizar u observar el Sitio.
            </Typography>
            <Typography className='textobase'>
                La sola utilización del Sitio le otorga la condición de Usuario, por lo que implica su aceptación, plena e incondicional de todas y cada una de las condiciones generales
                y particulares incluidas en estos Términos y Condiciones.
            </Typography>
            <Typography className='textobase'>
                En cualquier momento podremos realizar cambios en nuestros servicios, por lo que publicaremos las modificaciones necesarias a estos Términos y Condiciones para que pueda
                revisarlas y en su caso, aceptarlas para seguir utilizando el Sitio.
            </Typography>


            <Typography className='textonegritas'>
                CLÁUSULA 13. LICENCIA Y LÍMITES DEL USO DEL SITIO
            </Typography>
            <Typography className='textobase'>
                De conformidad con los presentes Términos y Condiciones, le otorgamos y concedemos como Usuario del Sitio, el derecho no exclusivo, revocable y no transferible
                de ver y utilizar el Sitio.
            </Typography>
            <Typography className='textobase'>
                Solo podrá imprimir o copiar cualquier información contenida o publicada en el Sitio exclusivamente para su uso personal, queda terminantemente prohibido su uso comercial.
                En todo momento podrá utilizar el Sitio, siempre y cuando no se afecte la explotación normal de este, respetando nuestra calidad de propietaria de los derechos patrimoniales
                y sin ninguna prospección comercial o que obtenga remuneración alguna, siempre citando invariablemente la fuente y sin alterar la obra del Sitio.
            </Typography>
            <Typography className='textobase'>
                La reimpresión, publicación, distribución, asignación, sublicencia, venta, reproducción electrónica o por cualquier otro medio, ya sea parcial o total, de cualquier
                información, dato personal, documento o gráfico que aparezca en el Sitio, para cualquier uso distinto al personal, le está expresamente prohibido como Usuario,
                a menos de que cuente con la autorización previa y por escrito de nuestra parte.
            </Typography>


            <Typography className='textonegritas'>
                CLÁUSULA 14. INFORMACIÓN EN EL SITIO
            </Typography>
            <Typography className='textobase'>
                El Sitio contendrá distinta información en su contenido, misma que podrá haber sido proporcionada por nosotros o por terceros como pueden ser Usuarios, las cuales
                estarán claramente identificadas y se sujetarán a las siguientes reglas:
            </Typography>

            <Typography className='subclausula bold'>a.	Información contenida en el Sitio.</Typography>

            <Typography className='textobase'>
                La información publicada o contenida en el Sitio es claramente identificada de tal forma que reconoce cual de esta proviene y ha sido generada por nosotros.
            </Typography>
            <Typography className='textobase'>
                El uso que dé del Sitio se realiza bajo su propio riesgo y responsabilidad, ya que los productos o servicios que se ofrecen en él se prevén sobre una base
                “tal cual” y “según sean disponibles”.
            </Typography>

            <Typography className='textobase'>
                Estaremos libres de cualquier responsabilidad en relación con la información contenida o disponible en o a través del Sitio, incluyendo, sin limitación alguna: 1)
                La disponibilidad de uso del Sitio; 2) La ausencia de virus, errores, desactivadores o cualquier otro material contaminante o con funciones destructivas en la información
                o programas disponibles en o a través del Sitio o en general cualquier falla en dicho Sitio; 3) Podremos actualizar el contenido del Sitio constantemente, por lo que se
                le solicita tomar en cuenta que alguna información publicitada o contenida en o a través del Sitio, puede haber quedado obsoleta o contener imprecisiones o errores
                tipográficos u ortográficos.
            </Typography>

            <Typography className='subclausula bold'>b.	Información compartida por terceros.</Typography>


            <Typography className='textobase'>
                La información publicada o contenida en el Sitio por parte de personas ajenas a nosotros, es claramente identificada de tal forma que reconoce cual de esta proviene y ha sido
                generada por terceros, por ejemplo, Usuarios como usted.
            </Typography>
            <Typography className='textobase'>
                La información, conceptos, calificaciones, experiencias u opiniones, entre otras, publicadas en el Sitio, no reflejan nuestra posición frente a un bien, producto o servicio,
                por esta razón, no nos responsabilizamos por ninguna de las informaciones, opiniones y conceptos que se emitan en el Sitio por medio de terceros. En este caso, se le recomienda
                consultar con un especialista o profesional en la materia correspondiente al bien, producto o servicio para aclarar cualquier duda sobre el mismo. Asimismo, no nos hacemos
                responsables del uso que se le dé a la información contenida en el Sitio, en el entendido de que el uso y seguimiento de esta es bajo su riesgo y responsabilidad.
            </Typography>

            <Typography className='textobase'>
                Reconoce que nosotros no controlamos o censuramos el contenido disponible en el Sitio, por tal motivo, no asumimos ninguna responsabilidad por el contenido provisto en el Sitio
                por terceros y reconoce que no tenemos el control editorial sobre el contenido, información o material generado o provisto por terceros. Todas las opiniones, consejos,
                declaraciones, servicios, ofertas u otras informaciones o contenidos expresados o puestos a disposición del público por terceros pertenecen a su respectivo autor y nosotros
                no asumimos responsabilidad alguna frente a estas. De la misma forma, No garantizamos la exactitud, veracidad, amplitud o utilidad de cualquier contenido provisto por tales terceros.
            </Typography>
            <Typography className='textobase'>
                Adicionalmente, no seremos responsables ni garantizamos la exactitud, exhaustividad, veracidad o confiabilidad de cualquier opinión, información, consejo o declaración expresados por
                terceros a través del Sitio y en ninguna circunstancia seremos responsables de cualquier daño o perjuicio, directo o indirecto, causado en virtud de la confianza que deposite en
                información obtenida a través del Sitio.
            </Typography>

            <Typography className='subclausula bold'>c.	Limitación de información en el Sitio</Typography>

            <Typography className='textobase'>
                Nos reservamos el derecho de bloquear el acceso o eliminar de manera parcial o total toda información, comunicación o material que a nuestro exclusivo juicio pueda resultar:
                1) Abusivo, difamatorio u obsceno; 2) Fraudulento, artificioso o engañoso; 3) Violatorio de derechos de autor, marcas, confidencialidad, secretos industriales o cualquier
                derecho de propiedad industrial; 4) Ofensivo o; 5) Que de cualquier forma contravenga lo establecido en estos Términos y Condiciones.
            </Typography>
            <Typography className='textobase'>
                Nos reservamos el derecho de suprimir o modificar el contenido del Sitio que a nuestro exclusivo juicio no cumpla con nuestros estándares de calidad o que pudiera resultar
                contrario al ordenamiento jurídico vigente y, por consiguiente, no seremos responsable por cualquier falla o tardanza que se genere al eliminar tal contenido.
            </Typography>

            <Typography className='textonegritas'>
                CLÁUSULA 15. DE LA PUBLICIDAD EN EL SITIO
            </Typography>
            <Typography className='textobase'>
                Nosotros somos una organización independiente de terceros patrocinadores y anunciantes cuya información, imágenes, anuncios y demás material publicitario o promocional
                (en lo subsecuente “material publicitario”) podrán ser publicados en el Sitio con fines publicitarios.
            </Typography>

            <Typography className='textonegritas PdL_2'>
                a.	Publicidad de terceros
            </Typography>
            <Typography className='textobase'>
                Todos los materiales publicitarios ajenos a nosotros en el Sitio son mostrados con base en las declaraciones que nos han realizado de que los mencionados materiales
                cumplen con todos los requisitos legales necesarios para ser lícitamente desplegados en el Sitio, esto por parte de los proveedores, autores o dueños de dicha publicidad.
                Salvo los bienes, productos o servicios comercializados con marcas que son propiedad de nuestra, nosotros no diseñamos, ni producimos la publicidad de bienes, productos
                o servicios que podrá encontrar en el Sitio, especialmente en materia de salud, lo cual es una obligación a cargo de los proveedores, autores o dueños de dicha publicidad,
                por lo anterior, reconoce que no tendremos responsabilidad alguna relacionada con dicho material y publicidad.
            </Typography>

            <Typography className='textonegritas PdL_2'>
                b.	Descripciones de bienes, productos o servicios
            </Typography>
            <Typography className='textobase'>
                Intentaremos ser tan precisos como sea posible en la descripción de los bienes, productos o servicios en el Sitio que ofertamos. No obstante, no garantizamos que la descripción
                de cualquier bien, producto o servicio sea precisa, completa, detallada, vigente o libre de errores. En el caso de que un producto o servicio anunciado en el Sitio no cumpla
                con la descripción indicada en el mismo, en el momento en que lo reciba podrá negarse a aceptarlo y procederemos a realizar la devolución de su pago correspondiente o a no
                recibir el pago correspondiente, según aplique. Usted será el único responsable de verificar que el bien, producto o servicio cumpla con la descripción señalada en el Sitio
                al momento de la entrega. Las imágenes que podrá encontrar en el Sitio son meramente ilustrativas y las características del producto pueden variar sin previo aviso.
            </Typography>

            <Typography className='textonegritas PdL_2'>
                c.	Precio ofrecido
            </Typography>
            <Typography className='textobase'>
                El precio que aparece para cada uno de los bienes, productos o servicios anunciados en el Sitio, representa el precio de venta al público final. En el Sitio podremos ofrecer
                otras promociones asociadas con la compra de bienes, productos o servicios, respecto de estas, tendrá derecho a mencionar dichas promociones que le sean aplicables y estaremos
                obligados a respetar las promociones vigentes en el Sitio.
            </Typography>
            <Typography className='textobase'>
                Por parte nuestra, toda aquella publicidad que aparezca en el Sitio y que sea publicitada por nosotros, se encuentra sujeta a la disponibilidad de los bienes, productos o servicios
                correspondientes. Esta publicidad únicamente se realiza sobre ofertas o promociones comerciales relacionadas exclusivamente con la existencia de bienes, productos o servicios y
                así como con el precio de estos; y no así con las características y opiniones de dichos bienes, productos o servicios, los cuales son responsabilidad de los proveedores
                correspondientes o de terceros como se ha señalado anteriormente.
            </Typography>


            <Typography className='textonegritas'>
                CLÁUSULA 16. USO DE SUS DATOS PERSONALES
            </Typography>
            <Typography className='textobase'>
                Con el uso del Sitio reconoce que, al proporcionarnos cualquier información o dato de carácter personal, nos otorga su más amplio consentimiento para que podamos utilizarlos
                según lo señalado en nuestro Aviso de Privacidad.
            </Typography>
            <Typography className='textobase'>
                Para hacer uso del Sitio, es necesario además de aceptar estos Términos y Condiciones, que lea y acepte nuestro Aviso de Privacidad, el cual podrá consultar en www.columbafunerales.com
            </Typography>


            <Typography className='textonegritas'>
                CLÁUSULA 17. DERECHOS DE AUTOR Y PROPIEDAD INDUSTRIAL
            </Typography>
            <Typography className='textobase'>
                Nuestro nombre, el Sitio, los logotipos, marcas y en general todo el material que aparece en dicho Sitio, son marcas, nombres de dominio, nombres comerciales, obras artísticas y en general,
                activos intangibles que son propiedad exclusiva de <Typography className='textonegritas'>COLUMBA</Typography>  y están protegidos por los tratados internacionales y las leyes aplicables en materia de propiedad intelectual, industrial y
                derechos de autor.
            </Typography>
            <Typography className='textobase'>
                Los derechos de autor sobre el contenido, organización, recopilación, compilación, información, logotipos, fotografías, imágenes, programas, aplicaciones y en general cualquier
                información contenida o publicada en el Sitio, se encuentran debidamente protegidos a nuestro nombre, de conformidad con la legislación aplicable en materia de propiedad intelectual,
                industrial y derechos de autor.
            </Typography>
            <Typography className='textobase'>
                Se le prohíbe expresamente modificar, alterar o suprimir, ya sea en forma total o parcial, los avisos, marcas, nombres comerciales, señas, anuncios, logotipos o en general cualquier
                indicación que se refiera a la propiedad de la información contenida en el Sitio señalado.
            </Typography>
            <Typography className='textobase'>
                En caso de que nos transmita cualquier información, programas, aplicaciones, software o en general cualquier material que requiera ser licenciado a través del Sitio, nos otorga
                con este acto una licencia perpetua, universal, gratuita, no exclusiva, mundial y libre de regalías, que incluye los derechos de sublicenciar, vender, reproducir, distribuir,
                transmitir, crear trabajos derivados, exhibirlos y ejecutarlos públicamente.
            </Typography>
            <Typography className='textobase'>
                Lo establecido en el párrafo anterior aplicará igualmente a cualquier información que nos envíe o transmita, incluyendo, sin limitación alguna, ideas para renovar o mejorar
                el Sitio, ya sea que estas hayan sido incluidas en cualquier espacio del Sitio o en virtud de otros medios de contacto. Por lo anterior, renuncia expresamente a llevar a
                cabo cualquier acción, demanda o reclamación en contra de nosotros por cualquier actual o eventual violación de cualquier derecho de autor o propiedad intelectual derivado
                de la información, programas, aplicaciones, software, ideas y demás material que nos envíe.
            </Typography>
            <Typography className='textobase'>
                Es nuestra política actuar contra las violaciones que en materia de propiedad intelectual o derechos de autor se pudieran generar u originar según lo estipulado en las leyes
                aplicables, incluyendo la eliminación o el bloqueo del acceso a material que se encuentra sujeto a actividades que infrinjan el derecho de propiedad intelectual de terceros.
                En caso de que considere que cualquiera de los contenidos que se encuentren en el Sitio o en cualquiera de sus productos o servicios violen sus derechos de propiedad intelectual,
                deberá de enviar una notificación a los medios de contacto señalados en estos Términos y Condiciones en la que indique: 1) Nombre, dirección, número de teléfono y dirección
                de correo electrónico; 2) Firma autógrafa; 3) Indicación precisa y completa del (los) contenido (s) protegido (s) mediante los derechos de propiedad intelectual supuestamente
                infringidos, así como la localización de dichas violaciones en el Sitio; 4) Declaración expresa y clara de que la introducción del (los) contenido (s) indicado (s)
                se ha realizado sin el consentimiento del titular de los derechos de propiedad intelectual supuestamente infringidos; y 5) Declaración expresa, clara y bajo la responsabilidad
                del reclamante de que la información proporcionada en la notificación es exacta y de que la introducción del (los) contenido (s) constituye una violación de dichos derechos.
            </Typography>



            <Typography className='textonegritas'>
                CLÁUSULA 18. LIMITACIONES A LA RESPONSABILIDAD
            </Typography>
            <Typography className='textobase'>
                No seremos responsables en ningún caso por daños directos, especiales, incidentales, indirectos o consecuenciales que en cualquier forma se deriven o se relacionen con: 1)
                El uso o ejecución del Sitio; 2) La proveeduría o falta de esta de servicios de cualquier información o gráficos contenidos o publicados en o a través del Sitio; 3)
                La actualización o falta de actualización de la información en el Sitio; 4) La alteración o modificación, total o parcial, de la información después de haber sido incluida
                en el Sitio; 5) Cualquier otro aspecto o característica de la información contenida o publicada en el Sitio; 6) La entrega, retraso o falta de entrega, proveeduría o falta
                de proveeduría de los bienes, productos o servicios; y 7) La suplantación de identidad, clonación o publicación de sitios apócrifos o fraudulentos, similares o clanes a los
                nuestros y que no correspondan a los sitios oficiales y que han sido indicados expresamente al inicio de estos términos y condiciones. Todos los supuestos anteriores
                serán vigentes, aún en los casos en que se le hubiere notificado o avisado acerca de la posibilidad de que se ocasionarán dichos daños.
            </Typography>


            <Typography className='textonegritas'>
            CLÁUSULA 19. MODIFICACIONES AL SITIO            
            </Typography>
            <Typography className='textobase'>
            Podremos en cualquier momento y cuando lo consideremos conveniente, sin necesidad de previo aviso, realizar correcciones, adiciones, mejoras o modificaciones al contenido,
             presentación, información, servicios, áreas, bases de datos y demás elementos del Sitio, así como de estos Términos y Condiciones, sin que ello le dé lugar ni derecho alguno
              a ningún tipo de reclamación o indemnización.
            </Typography>
            <Typography className='textobase'>
            En caso de que modifiquemos estos Términos y Condiciones, antes de que este cambio será efectivo; dichas notificaciones las haremos por medio de una publicación en el Sitio
             de los nuevos Términos y Condiciones.
            </Typography>
            <Typography className='textobase'>
            De esta forma, se compromete en revisar estos Términos y Condiciones periódicamente con la finalidad de mantenerse al tanto de posibles modificaciones. Independientemente
             de lo anterior, cada vez que utilice el Sitio, se considerará como una aceptación absoluta a los Términos y Condiciones vigentes.
            </Typography>


            <Typography className='textonegritas'>
            CLÁUSULA 20. TÉRMINOS ADICIONALES            
            </Typography>
            <Typography className='textobase'>
            Podremos agregar a estos Términos y Condiciones elementos adicionales relativos a áreas específicas, promociones, descuentos o productos o servicios específicos,
             los cuales serán publicados en el Sitio para su lectura y aceptación. Reconoce y acepta que estos Términos Adicionales forman parte integral de los presentes Términos
              y Condiciones para todos los efectos legales a que haya lugar y si existe alguna discrepancia entre estos dos, siempre prevalecerá lo señalado en estos Términos y Condiciones.
            </Typography>


            <Typography className='textonegritas'>
            CLÁUSULA 21. USO DE AGENTES EXTERNOS            
            </Typography>
            <Typography className='textobase'>
            En el caso de que acceda al Sitio a través de agentes externos tecnológicos como pueden ser, de manera enunciativa mas no limitativa: Google Home, Amazon Alexa, 
            Windows Cortana, entre otros similares, le informamos que sus datos, grabaciones de voz y en general cualquier información que ahí exponga, serán tratados bajo
             la exclusiva responsabilidad del fabricante correspondiente a dicho dispositivo, por lo cual, nos deslindamos del uso, tratamiento, almacenamiento y cualquier
              trato que se le dé a sus datos personales ahí vertidos.
            </Typography>


            <Typography className='textonegritas'>
            CLÁUSULA 22. CESIÓN DE DERECHOS            
            </Typography>
            <Typography className='textobase'>
            Podremos en cualquier momento y cuando así lo estimemos conveniente, ceder total o parcialmente los derechos y obligaciones derivados de estos Términos y Condiciones
             usted como Usuario. En virtud de dicha cesión, quedaremos liberados de cualquier obligación y responsabilidad establecida en estos Términos y Condiciones.
            </Typography>


            <Typography className='textonegritas'>
            CLÁUSULA 23. INDEMNIZACIÓN            
            </Typography>
            <Typography className='textobase'>
            Deberá de indemnizarnos así como reembolsarnos cualquier gasto que tengamos que erogar, por cualquier acción, demanda o reclamación
             (incluso honorarios de abogados y de costas judiciales) derivadas de cualquier incumplimiento de su parte a los presentes Términos
              y Condiciones; entre los cuales podrán considerarse, sin limitación de alguna de las derivadas de: 1) Cualquier aspecto relativo
               al uso del Sitio; 2) La información contenida o disponible en o a través del Sitio o de injurias, difamación o cualquier otra
                conducta violatoria del presente convenio de su parte en el uso del Sitio; 3) La violación a las leyes aplicables o tratados
                 internacionales relativos a los derechos de autor o propiedad intelectual, contenidos o disponibles en, o a través del Sitio.
            </Typography>


            
            <Typography className='textonegritas'>
            CLÁUSULA 24. TERMINACIÓN            
            </Typography>
            <Typography className='textobase'>
            Nos reservamos el derecho a nuestra exclusiva discreción y sin necesidad de darle aviso o notificación para: 1) Terminar en
            cualquier momento definitivamente estos Términos y Condiciones; y 2) Descontinuar o dejar de publicar contenido definitivamente el Sitio.
            </Typography>



            
            <Typography className='textonegritas'>
            CLÁUSULA 25. SUBSISTENCIA            
            </Typography>
            <Typography className='textobase'>
            Estos Términos y Condiciones, así como los Términos Adicionales, constituyen un acuerdo íntegro entre usted y nosotros, y sustituyen cualquier
             otro acuerdo o convenio celebrado con anterioridad. Cualquier cláusula de los presentes Términos y Condiciones, así como de los Términos
              Adicionales, en caso de que fuera judicialmente declarada inválida, será eliminada o modificada a elección nuestra, con la finalidad de 
              corregir su vicio o defecto. Sin embargo, el resto de las cláusulas mantendrán su fuerza, obligatoriedad y validez.
            </Typography>

            <Typography className='textonegritas'>
            CLÁUSULA 26. RENUNCIA DE DERECHOS            
            </Typography>
            <Typography className='textobase'>
            La inactividad de nuestra parte a cualquier ejercicio de cualquier derecho o acción derivados de los presentes Términos y Condiciones,
             en ningún momento deberá interpretarse como renuncia a dichos derechos o acciones.
            </Typography>



            
            <Typography className='textonegritas'>
            CLÁUSULA 27. USO DE SUS DATOS CON FINES DE MERCADOTECNIA            
            </Typography>
            <Typography className='textobase'>
            En todo momento tendrá la posibilidad de decidir si acepta o no que podamos enviarle publicidad sobre nuestros bienes, productos o servicios. 
            De igual forma, podrá decidir en todo momento sobre si nos autoriza o no podamos transmitir a terceros con fines mercadotécnicos o publicitarios, 
            la información que nos ha proporcionado para el uso del Sitio.
            </Typography>
            <Typography className='textobase'>
            En caso de que no acepte que su información sea cedida o transmitida a terceros con fines mercadotécnicos o publicitarios y/o no acepte que le enviemos 
            publicidad sobre bienes, productos o servicios, no existirá discriminación alguna en su contra por este hecho.
            </Typography>


            <Typography className='textonegritas'>
            CLÁUSULA 28. LEGISLACIÓN APLICABLE Y JURISDICCIÓN          
            </Typography>
            <Typography className='textobase'>
            Para todo lo relacionado a la interpretación y cumplimiento de los presentes Términos y Condiciones, nos someteremos en primer lugar al procedimiento de conciliación 
            de la Procuraduría Federal del Consumidor en Toluca, Estado de México y a las leyes y tribunales competentes de Guadalajara, Jalisco, México; renunciando a cualquier 
            otra jurisdicción que pudiera generarse por razón de domicilios presentes o futuros o por cualquier otra causa.
            </Typography>

        </Box>
    );
}

export default AcuerdoTerminosCondiciones;