//-------------Importar librerias----------------------------
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import $ from 'jquery';
import TodoDataService from '../../hooks/services'
import styled from "styled-components";

// -----------Importar Archivos Estaticos------------------
import cvv from '../../assets/Imagenes/Sets/BBVA-Pagos/cvv.png'
import cards2 from '../../assets/Imagenes/Sets/BBVA-Pagos/cards2.png'
import cards1 from '../../assets/Imagenes/Sets/BBVA-Pagos/cards1.png'
import openpaysecuryty from '../../assets/Imagenes/Sets/BBVA-Pagos/openpay-securyty.PNG'

// -----------Importar Componentes de Terceros------------------
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';


import './style.css'

// -------------Valores Constantes-------------------------------
const StyleDragArea = styled.div`
  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
  }

  input[type=number] { -moz-appearance:textfield; }

  textarea:focus {
      background-color: white;
      color: black;
      border: 1px solid white !important;
      outline: none !important;
      outline-color: var(--color-amarilo) !important;
      box-shadow: 0 0 0 0.1rem var(--color-amarilo) !important;
    }
`;


const OpenPayBBVA = ({ Style, CambiarFormaPago, PedidoCliente, OnClickHandleNext, Token }) => {
    const [DeviceSessionId, setDeviceSessionId] = useState('');
    const [Alerta, setAlerta] = useState(false);
    const [AlertaAutorizacion, setAlertaAutorizacion] = useState(false);
    const [Tarjeta, setTarjeta] = useState('');
    const [Mes, setMes] = useState('');
    const [Año, setAño] = useState('');
    const [Codigo, setCodigo] = useState('');
    const [AlertaPago, setAlertaPago] = useState(false);



    const HandleChangeNumber = (e) => {

        if (e.target.name === 'Tarjeta') {
            if (e.target.value.length < 17) {
                setTarjeta(e.target.value);
            }
        }
        if (e.target.name === 'Año') {
            if (e.target.value.length < 3) {
                setAño(e.target.value);
            }
        }
        if (e.target.name === 'Mes') {
            if (parseInt(e.target.value) < 13) {
                setMes(e.target.value);
            }
        }
        if (e.target.name === 'Codigo') {
            if (parseInt(e.target.value) < 1000) {
                setCodigo(e.target.value);
            }
        }
    }


    // Función encargada de realizar el cargo a la tarjeta.
    const successCallback = async (response) => {
        const url = "https://sandbox-api.openpay.mx/v1/msciz3cejztuuxk9q4jk/charges";
        const username = "sk_e66dc1bf28c342429c826ec5eb4e55d0";
        const password = "";
        const chargeRequest = {
            'source_id': response.data.id, // Token generado por OpenPay
            'method': 'card',
            'amount': 100, // Cantidad a cobrar en centavos (100 pesos)
            'currency': 'MXN', // Moneda
            'description': ' Cargo 3D Secure ', // Descripción del cargo
            'device_session_id': DeviceSessionId,
            'order_id': `oid-${PedidoCliente.folio}`,
            'customer': {
                'name': PedidoCliente.contactoCliente.nombre,
                'last_name': PedidoCliente.contactoCliente.apPaterno + " " + PedidoCliente.contactoCliente.apMaterno,
                'phone_number': PedidoCliente.contactoCliente.celular,
                'email': PedidoCliente.contactoCliente.email,
            },
            "redirect_url": "https://clmb.proximasistemas.com.mx/",
            "use_3d_secure": "true"
        };

        const headers = {
            Authorization: "Basic " + btoa(username + ":" + password),
            "Content-Type": "application/json",
        };



        await axios
            .post(url, JSON.stringify(chargeRequest), {
                headers: headers,
            })
            .then((response) => {
                console.log(response);
                setAlertaPago(true);
                TodoDataService.PostAnticipoCliente({
                    codEstab: '2', codCte: '0021', Autorizacion: response.data.authorization,
                    importe: PedidoCliente.total, idOperacion: response.data.id,
                    FolioOrigen: PedidoCliente.folio, formaPago: '3', usuario: '1'
                }, Token, () => (window.open(`${response.data.payment_method.url}`, "_blank")), setAlertaAutorizacion)
            })
            .catch((error) => {
                console.error(error);
                alert('Error al realizar el pago, consulte con su intitucion bancaria');
                setAlerta(true)
            });
    };

    // Función encargada de generar una alerta, cuando se generar un error en la función para generar una un token de la tarjeta 
    const errorCallback = (response) => {
        const desc = response.data.description !== undefined ? response.data.description : response.message;
        setAlerta(true)
        alert("ERROR [" + response.status + "] " + desc);
        $("#pay-button").prop("disabled", false);
    };

    // Función encargada de generar un token para la tarjeta de débito o crédito cargada en el formulario, para lo cual se validara la autenticidad y dependieno de la repuesta lalama a la funcion successCallback o errorCallback.
    const handlePayButtonClick = (event) => {
        event.preventDefault();
        $("#pay-button").prop("disabled", true);
        window.OpenPay.token.extractFormAndCreate('payment-form', successCallback, errorCallback);
    };





    // Función encargada a de generar device session id, indispensable para pode utilizar formularios de cobranza personalizados, es llamada al cargar el componente
    useEffect(() => {
        window.OpenPay.setId('msciz3cejztuuxk9q4jk');
        window.OpenPay.setApiKey('pk_f9c7e538d9c54aea821f9d746cdf944d');
        window.OpenPay.setSandboxMode(true);
        const deviceSessionId = window.OpenPay.deviceData.setup("payment-form", "deviceIdHiddenFieldName");
        setDeviceSessionId(deviceSessionId);
    }, []);

    return (
        AlertaPago === true
            ?
            <>
                <Typography sx={{ color: '#D14081', fontSize: '20px', fontWeight: 'bold !important' }}>
                La validación de la información de pago no fue exitosa. Para resolver este inconveniente, por favor contáctenos a través de nuestras líneas de comunicación:
                722 784 6600
                </Typography>
            </>
            :
            <>
                <div id="head"></div>

                <div style={{ backgroundColor: '#f7f7f7', borderRadius: '10px' }}>
                    <form id="payment-form">
                        <input type="hidden" id="deviceIdHiddenFieldName" name="deviceIdHiddenFieldName" />
                        <input type="hidden" name="use_card_points" id="use_card_points" value="false" />
                        <div className='Marcesina' >
                            <h2 className='titulopago'>Tarjeta de crédito o débito</h2>
                        </div>

                        <div style={{ margin: '10px' }}>
                            <div className='row' >
                                <div className='col-md-4'>
                                    <h4>Tarjetas de crédito</h4>
                                    <img className='cards' alt='Cards01' src={cards1} />

                                </div>
                                <div className='col-md-8'>
                                    <h4>Tarjetas de débito</h4>
                                    <img className='cards' alt='Cards01' src={cards2} />
                                </div>
                            </div>
                            <div className='row mt-4'>
                                <div className='col-md-6'>
                                    <label>Nombre del titular</label>
                                    <input className='InputGenral form-control' type="text" placeholder="Como aparece en la tarjeta" autocomplete="off" data-openpay-card="holder_name" />
                                </div>
                                <div className='col-md-6'>
                                    <label>Número de tarjeta</label>
                                    <StyleDragArea>
                                        <input value={Tarjeta} onChange={HandleChangeNumber} className='form-control InputGenral ' name='Tarjeta' type="number" autocomplete="off" data-openpay-card="card_number" />
                                    </StyleDragArea>
                                </div>
                            </div>
                            <div className='row mt-4'>
                                <div className='col-md-6'>
                                    <label>Fecha de expiración</label>
                                    <div className='row' >
                                        <div className='col-5'>
                                            <StyleDragArea>
                                                <input value={Mes} onChange={HandleChangeNumber} className='form-control InputGenral ' type="number" name='Mes' placeholder="Mes" data-openpay-card="expiration_month" />
                                            </StyleDragArea>
                                        </div>
                                        <div className='col-2'>

                                        </div>
                                        <div className='col-5'>
                                            <StyleDragArea>
                                                <input value={Año} onChange={HandleChangeNumber} className='form-control InputGenral' type="number" name='Año' placeholder="Año" data-openpay-card="expiration_year" />
                                            </StyleDragArea>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-md-6'>
                                    <label>Código de seguridad</label>
                                    <div className='row'>
                                        <div className='col-4'>
                                            <StyleDragArea>
                                                <input value={Codigo} onChange={HandleChangeNumber} className='form-control InputGenral ' type="number" name='Codigo' placeholder="3 dígitos" autocomplete="off" data-openpay-card="cvv2" />
                                            </StyleDragArea>

                                        </div>
                                        <div className='col-8'>
                                            <img height={40} alt='Cards03' className='cards' src={cvv} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='row mt-4'>
                                <div className='col-md-5'></div>
                                <div className='col-md-7'>
                                    <img className='cards' alt='Cards04' src={openpaysecuryty} />
                                </div>
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'flex-end' }} className=' mt-4'>
                                <Style style={{ width: '100px' }}>
                                    <button className="btnAmarilloChicos " id="pay-button" onClick={handlePayButtonClick}>Pagar</button>
                                </Style>
                            </div>
                            <div className='row mt-4'></div>
                        </div>
                    </form>

                </div>

                {
                    Alerta === true
                        ?
                        <div>
                            <Typography sx={{ color: '#D14081', fontSize: '20px', fontWeight: 'bold !important' }}>
                                Pudes cambiar tu forma de pago si tienes problemas para realizar la transaccion.
                            </Typography>

                            <Link onClick={CambiarFormaPago} align="left" color='#D14081' sx={{ marginLeft: '20px', fontSize: '15px', fontFamily: 'Itaca ', '&:hover': { color: '#D95F96' } }} component="button" variant="body2" >
                                Cambiar forma de pago
                            </Link>
                        </div>
                        :
                        <></>
                }

                {
                    AlertaAutorizacion === true
                        ?
                        <div>
                            <Typography sx={{ color: '#D14081', fontSize: '20px', fontWeight: 'bold !important' }}>
                                Su cargo se ha realizado con éxito. Sin embargo, ocurrió un error al intentar autorizar su servicio. Por favor, contacte a nuestro servicio de atención al cliente para recibir asistencia. Su folio de servicio es: {PedidoCliente.folio}.
                            </Typography>
                        </div>
                        :
                        <></>
                }
            </>
    );
}

export default OpenPayBBVA;




