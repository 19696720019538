//-------------Importar librerias----------------------------
import React, { Component } from "react";
import { Navigate } from "react-router-dom";
import styled from "styled-components";
import TodoDataService from '../../hooks/services'

// -----------Importar Componentes Personalizados------------------
import ModalBaseMui from "../../components/Modal_Mui";

// -----------Importar Componentes de Terceros---------------------
import Slider from "react-slick";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import ImageListItem from '@mui/material/ImageListItem';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

// -----------Importar Archivos Estaticos------------------
import './styles.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// ------------------------Valores Constantes----------------------------
import Columba1 from '../../assets/Imagenes/Home/slider/columba1B.jpg'
import Columba2 from '../../assets/Imagenes/Home/slider/columba2.jpg'
import Columba3 from '../../assets/Imagenes/Home/slider/columba3.jpg'
import Mask35 from '../../assets/Imagenes/Home/Mask 35.png'
import Mask35Movil from '../../assets/Imagenes/Home/new marquesi.png'
import VideoConmemeracion from '../../assets/Videos/conmemoración.mp4'
import VideoConmemeracionModal from '../../assets/Videos/Plan_futuro.mp4'



class Home extends Component {
  state = {
    Conmemoracion: false,
    Route: null,
    windowWidth:window.innerWidth
  }

  ShowConmemoracion = () => {
    this.setState({ Conmemoracion: !this.state.Conmemoracion })
  }

  componentDidMount() {
    const handleResize = () => {
      this.setState({windowWidth:window.innerWidth});
    };

    // Agregar el event listener para el evento 'resize'
    window.addEventListener('resize', handleResize);

    // Limpiar el event listener cuando el componente se desmonta
    return () => {
      window.removeEventListener('resize', handleResize);
    };
    
  }



  render() {
    const StyleDragArea = styled.div`

    
.btn_consultar {background-color: #d14081;
  color: white;
  width: 200px;
  margin: auto;
  font-weight: 500;
  border-radius: 6px;
  border:1px solid #d14081;
}
.btn_consultar:hover{
  background-color: transparent;
border: #d14081 1px solid;
color: #d14081;
}
.btn_consultar:focus{
  color: white;
    background-color: #932C59;
    box-shadow: 0 0 0 1px rgb(255 39 136);
}
.btn_consultar:active{
  color: white !important;
  background-color: #932C5A !important;
  border-color: #932c5a !important;
}


.boton_amarilo_comienza{
  width: 250px !important;
  height: 49px;
  font-size: 20px;
}
.boton_amarillo{
  padding: 5px 22px;
    background-color: #FFEC00;
    color: #484848;
    border: none;
    font-weight: 500;
    border-radius: 5px;
}
.boton_amarillo:hover{
  border: 1px solid #FFEC00;
    background-color: white;
    color: #6e6e6e;
}
.boton_amarillo:not(:disabled):not(.disabled).active, .boton_amarillo:not(:disabled):not(.disabled):active, .show>.boton_amarillo.dropdown-toggle {
  color: #fff;
background-color: #BFB31C;
border-color: #938a1e;
}

    `;



    const settings = {
      autoplay: true,
      dots: true,
      infinite: true,
      speed: 500,
      fade: true,
      cssEase: 'linear',
    };


    let { Route } = this.state;
    console.log(this.state.windowWidth);

    return (
      <>
        {Route && (
          <Navigate to={Route} replace={true} />
        )}
        <Box className="page-wrapper">

          <Box className="hero-slider">
            <Slider  {...settings}>
              <Box className="slider-item slide1" sx={{ backgroundImage: `url(${Columba1})` }}>

                <Box className="container">
                  <Box className="row">
                    <Box className={this.state.windowWidth<600?"col-12":"col-6"}>
                      <Box className="content style text-left">
                        <Typography variant="h2" className="text-bold mb-2" data-animation-in="slideInLeft"
                          sx={{ textAlign: 'left', opacity: '1', padding: '5px', color: '#d63384 !important', fontSize: '38px !important', fontWeight: '600 !important', borderRadius: '5px' }}>Despide con amor  <br />
                         <Typography sx={{fontSize:'26px', color: '#d63384 !important',fontWeight:'600 !important'}}>24 horas</Typography> </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-start'}}>
                          <StyleDragArea>
                            <button onClick={() => (window.open(TodoDataService.GetNumberPhone(), "_blank"))} data-animation-in="slideInRight" style={{ margin: 'initial' }} type="button"
                              className="btn btn-block btn_consultar">Contáctanos ahora</button>
                          </StyleDragArea>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box className="slider-item" sx={{ backgroundImage: `url(${Columba2})` }}>
                <Box className="container">
                  <Box className="row">
                    <Box className={this.state.windowWidth<600?"col-12":"col-6"}>
                      <Box className="content style text-left">
                      <Typography variant="h2" className="text-bold mb-2" data-animation-in="slideInLeft"
                          sx={{ textAlign: 'left', opacity: '1',padding:'5px', color: 'rgb(255, 255, 255) !important', fontSize: '38px !important', fontWeight: '600 !important', borderRadius: '5px' }}>Crea un recuerdo duradero  <br />
                         <Typography sx={{fontSize:'26px', color: 'rgb(255, 255, 255) !important',fontWeight:'600 !important'}}>conmemoración columba</Typography> </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                          <StyleDragArea>
                            <button onClick={() => (window.open(TodoDataService.GetNumberPhone(), "_blank"))} data-animation-in="slideInRight" style={{ margin: 'initial' }} type="button"
                              className="btn btn-block btn_consultar">Contáctanos ahora</button>
                          </StyleDragArea>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box className="slider-item" sx={{ backgroundImage: `url(${Columba3})` }}>
                <Box className="container">
                  <Box className="row">
                    <Box className={this.state.windowWidth<600?"col-12":"col-6"}>
                      <Box className="content style text-left">
                      <Typography variant="h2" className="text-bold mb-2" data-animation-in="slideInLeft"
                          sx={{ textAlign: 'left', opacity: '1',padding:'5px', color: 'rgb(255, 255, 255) !important', fontSize: '38px !important', fontWeight: '600 !important', borderRadius: '5px' }}>La mejor forma de invertir<br />
                         <Typography sx={{fontSize:'26px', color: 'rgb(255, 255, 255) !important',fontWeight:'600 !important'}}>tu dinero ante un futuro inevitable.</Typography> </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                          <StyleDragArea>
                            <button onClick={() => (window.open(TodoDataService.GetNumberPhone(), "_blank"))} data-animation-in="slideInRight" type="button"
                              className="btn btn-block btn_consultar">Contáctanos ahora</button>
                          </StyleDragArea>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box >
            </Slider>
          </Box>
          {/* </div > */}


        </Box >

        {/* --- */}

        {
          this.state.windowWidth !== ''
          ?
          <Box  sx={{ backgroundColor: '#ffec00', borderTop: ' 7px solid #FFC200' }}>
          <ImageListItem>
            <img alt="imagen fondo" src={this.state.windowWidth===''?Mask35:this.state.windowWidth<600?Mask35Movil:Mask35} />
            <Box sx={{ position: 'absolute', width: { xs: '96%', sm: '97', md: '98%', lg: '99%' }, zIndex: 21, top: this.state.windowWidth===''?'0%':this.state.windowWidth<600?'0%':'0%' }} >
              <Box  className="row MyComponent2">
                <Box className="col-md-12  text-center">
                  <Typography mt='3rem' mb='1.5rem' variant="h2"  sx={{  fontWeight: '600 !important', fontSize:'32px', color: '#D14081' }} className="animate__animated animate__fadeIn animate__delay-1s textMarquesina">¿Por qué elegir a Columba?</Typography>
                  <Typography sx={{ fontSize:this.state.windowWidth===''?{ xs: '14px', sm: '14px', md: '18px', xl: '24px' } :this.state.windowWidth<450?'12px': { xs: '14px', sm: '14px', md: '18px', xl: '24px' }, lineHeight:{xs: '15px', sm:'20px',md:'35px'} , color: '#3a3a3a', fontWeight: '400 !important' }}>Columba facilita el proceso de
                    contratación funeraria con soluciones
                    <Typography sx={{ fontSize: this.state.windowWidth===''?{ xs: '14px', sm: '14px', md: '18px', xl: '24px' } :this.state.windowWidth<450?'12px': { xs: '14px', sm: '14px', md: '18px', xl: '24px' }, lineHeight: {xs: '15px', sm:'20px',md:'35px'} , color: '#3a3a3a', fontWeight: '400 !important' }}> integrales a las que puedes acceder desde cualquier dispositivo.</Typography>
                  </Typography>
                </Box>
              </Box>
            </Box>
          </ImageListItem>
        </Box>
          :
          <></>
        }

        


        <div >

          <section style={{margin:'0px', background: 'linear-gradient(to bottom, #F5F5F5 70%, #ffffff 0%)'}}>
            <Container  maxWidth='lg' >

              <div className="Servicios" style={{ margin: '0px 25px ' }}>
                <div className="row mb-0">
                  <div className="col-md-12 mb-0 text-center">
                    <Typography mb='1.5rem !important' mt='3rem !important' variant="h2" sx={{ fontSize: '32px', fontWeight: '600 !important', color: '#d14081' }} className="animate__animated animate__fadeIn animate__delay-1s mt-3 mb-2">Nuestros servicios</Typography>
                    <Typography className="mb-5 mt-0" sx={{padding:'0px', fontSize: '24px', lineHeight: '35px', fontWeight: '400 !important', color: '#3a3a3a' }}>Contamos con servicios funerarios en
                      tu casa o en nuestras instalaciones;incluyen logística, instalación de sillas, carpa, cafetería, entre
                      otros, para la despedida de tu ser querido.</Typography>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 text-center ">
                    <Typography variant="h3" sx={{fontWeight:'600 !important', fontSize: '28px',  color: '#3d3d3d' }} className="animate__animated animate__fadeIn animate__delay-1s mb-1">Plan futuro</Typography>
                    <div className="planafuturo source2">
                    </div>
                    <Typography className="mt-2 target2" sx={{ fontSize: '0px', fontWeight: '400 !important', color: '#3a3a3a' }}>Contamos con paquetes que se adquieren con
                      anticipación,<br />los cuales se adecúan al bolsillo de nuestros clientes.</Typography>
                    <StyleDragArea>
                      <button style={{ marginBottom: '0px' }} onClick={() => (this.setState({ Route: '/PlanesFuturo' }))} type="button" className="btn btn-block btn_consultar mt-3">Contrata ahora</button>
                    </StyleDragArea>
                  </div>

                  <div className="col-md-6 text-center ">
                    <Typography variant="h3" sx={{fontWeight:'600 !important', fontSize: '28px',  color: '#3D3D3D' }} className="animate__animated animate__fadeIn animate__delay-1s mb-1">Sets funerario</Typography>
                    <div className="planafuturo2 source">
                    </div>
                    <Typography className="mt-2 target" sx={{ fontSize: '0px', fontWeight: '400 !important', color: '#3a3a3a' }}>Son conmemoraciones inesperadas,<b />las cuales necesitan de
                      este servicio de forma urgente.</Typography>
                    <StyleDragArea>
                      <button onClick={() => (this.setState({ Route: '/SetsFunerarios' }))} type="button" className="btn btn-block btn_consultar mt-3 ">Contrata ahora
                      </button>
                    </StyleDragArea>
                  </div>
                </div>
              </div>

            </Container >
          </section >

          <Container  maxWidth='lg'>

            <section className="ContenedorSets" style={{ margin: '0px 30px' }}>
              <div >
                <div  className="row ">
                  <div className="col-lg-12 mb-1 text-center">
                    <Typography mb='1.5rem' mt='3rem !important' variant="h2" sx={{ fontSize: '32px', fontWeight: '600 !important', color: '#d14081' }} className="animate__animated animate__fadeIn animate__delay-1s mt-4 mb-4">¿Cuáles son nuestros sets?</Typography>
                  </div>
                </div>
                <div style={{ padding: '0px 500px !important' }} className="row">
                  <div onClick={() => (this.setState({ Route: '/SetsFunerarios/Cobertura/1' }))} style={{}} className="col-9 col-sm-6 col-md-4 text-center zoom ContenedorSet">
                    <div className="setcasa">
                    </div>
                    <p className="mt-2 setstexto">Velación en casa</p>
                  </div>
                  <div onClick={() => (this.setState({ Route: '/SetsFunerarios/Cobertura/2' }))} className="col-9 col-sm-6 col-md-4 text-center zoom ContenedorSet">
                    <div className="setsala">
                    </div>
                    <p className="mt-2 setstexto">Velación en sala</p>
                  </div>
                  <div onClick={() => (this.setState({ Route: '/SetsFunerarios/Cobertura/3' }))} className="col-9 col-sm-6 col-md-4 text-center zoom ContenedorSet">
                    <div className="setdirecto">
                    </div>
                    <p className="mt-2 setstexto">Servicio directo</p>
                  </div>
                  <div className="col-md-12 text-center">
                    <Typography className="mb-0" sx={{ fontSize: '20px', fontWeight: '400 !important', lineHeight: '35px' }}>En todos nuestros sets te apoyamos de principio a
                      fin con los trámites de defunción.</Typography>
                    <Typography className="mb-4" sx={{ display: 'inline', fontSize: '15px', lineHeight: '35px', fontWeight: '400 !important' }}>
                      <Typography sx={{ display: 'inline', fontSize: '15px', lineHeight: '35px', fontWeight: '700 !important' }}>*Cobertura gratuita de 50 km </Typography>a la redonda de nuestras instalaciones.
                    </Typography>
                  </div>
                </div>
              </div>
            </section>
          </Container>



          <section  style={{background: 'linear-gradient(to bottom, #FFFCEC 85%, #ffffff 0%)' }}>
            <Box sx={{ padding: {xs:'0% 6%', sm:'0% 6%', md:'0% 9%', lg:'0% 0%', xl:'0% 18%'} }}>
              <div className="row">
                <div className="col-md-12 text-center">
                  <Typography mt="3rem !important" mb='1.5rem' variant='h2' sx={{ fontSize:'32px', color: '#D14081', fontWeight: '600 !important' }} className="animate__animated animate__fadeIn animate__delay-1s mt-2">      ¿Qué es una conmemoración?
                  </Typography>
                </div>
              </div>
              <div className="row">
                <div  className="col-md-6 text-center videconmemoracion">
                  <div className="">
                    <a onClick={this.ShowConmemoracion} href="#" data-toggle="modal" data-target="#myModal" className="quest  zoom">
                      <video src={VideoConmemeracion} width="480px" height="480px" className="Conmemoracionvideo" controls muted autoPlay style={{ borderRadius: '15px' }}></video>
                    </a>
                  </div>
                </div>
                <div  className="col-md-5 text-justify">
                  <Typography className="mt-2" sx={{ fontWeight: '400 !important', textAlign: 'justify', fontSize: '24px', lineHeight: '35px', color: '#343434' }}>La conmemoración es una forma de
                    recordar la vida de nuestros seres queridos por medio de fotografías, video y mensajes que se podrán
                    compartir por medios digitales.</Typography>
                  <StyleDragArea className="align">
                    <button onClick={() => (this.setState({ Route: '/Conmemoraciones' }))} type="button" className="btn btn-block btn_consultar mt-3">Ver más</button>
                  </StyleDragArea>
                </div>
              </div>
            </Box>
          </section>

          <ModalBaseMui Title='' Open={this.state.Conmemoracion} FullScreen={false} OnClickClose={this.ShowConmemoracion} FullWidth={true} MaxWidth='sm'
            ContentModal={
              <div className="modal-body pt-0">
                <h5 className="color_ros modal-title text-center pb-3" id="exampleModalLabel">¿Qué es una conmemoración?</h5>
                <div className="" style={{padding:'0% 0%'}}>
                  <video src={VideoConmemeracionModal} width="100%" controls muted autoPlay style={{ borderRadius: '15px' }}></video>
                </div>
              </div>
            }

          />

          <section className=" MyComponent" >
            <div className="container">
              <div className="row">
                <div className="col-md-12  text-center">
                  <Typography mb='1.5rem' mt='3rem' variant="h2" sx={{ fontSize: '32px', color: '#D14081', fontWeight: '600 !important' }} className="animate__animated animate__fadeIn animate__delay-1s mt-5 mb-4">¿Cómo funcionan nuestros servicios?
                  </Typography>
                </div>
              </div>
              <div className="row">
                <div style={{}} className="animate__animated animate__bounceIn animate__delay-2s col-sm-3 text-center p-0">
                  <div className="personaliza linestep">
                  </div>
                  <Typography className="mt-2 mb-0 setstexto" sx={{ fontSize: '20px', fontWeight: '500 !important' }}>Selecciona</Typography>
                  <Typography className="mt-0" sx={{ fontSize: '15px', lineHeight: '20px', fontWeight: '400 !important' }}> Selecciona el set funerario<br />que se adapta a
                    tus necesidades.</Typography>
                </div>
                <div style={{}} className="animate__animated animate__bounceIn animate__delay-2s col-sm-3 text-center p-0">
                  <div className="selecciona linestep">
                  </div>
                  <Typography className="mt-2 mb-0 setstexto" sx={{ fontSize: '20px', fontWeight: '500 !important' }}>Personaliza</Typography>
                  <Typography className="mt-0" sx={{ fontSize: '15px', lineHeight: '20px', fontWeight: '400 !important' }}>Conoce nuestros sets y elige <br />los que te
                    interesan.</Typography>
                </div>
                <div style={{}} className="animate__animated animate__bounceIn animate__delay-2s col-sm-3 text-center p-0">
                  <div className="ordena linestep">
                  </div>
                  <Typography className="mt-2 mb-0 setstexto" sx={{ fontSize: '20px', fontWeight: '500 !important' }}>Ordena y paga</Typography>
                  <Typography className="mt-0" sx={{ fontSize: '15px', lineHeight: '20px', fontWeight: '400 !important' }}>Corrobora tu compra e<br />ingresa los datos de tu
                    tarjeta.</Typography>
                </div>
                <div style={{}} className="animate__animated animate__bounceIn animate__delay-2s col-sm-3 text-center p-0">
                  <div className="confirma linestep">
                  </div>
                  <Typography className="mt-2 mb-0 setstexto" sx={{ fontSize: '20px', fontWeight: '500 !important' }}>Confirmación telefónica</Typography>
                  <Typography className="mt-0" sx={{ fontSize: '15px', lineHeight: '20px', fontWeight: '400 !important' }}>Ahora solo espera nuestra llamada<br />y nosotros
                    nos encargaremos.</Typography>
                </div>
                <div style={{ textAlign: 'right' }} className="col-md-12 mt-2 text-right  mt-4 align">
                  <StyleDragArea>
                    <button onClick={() => (this.setState({ Route: '/SetsFunerarios' }))} className="btn boton_amarillo boton_amarilo_comienza ">Comienza ahora</button>
                  </StyleDragArea>

                </div>
              </div>
            </div>
          </section>
          

          <div className="scroll-to-top scroll-to-target" data-target=".header-top">
            <span href='#ancla-1' className="icon fa fa-angle-up"></span>
          </div>

        </div>


      </>


    );
  }
}

export default Home;